import React, { useState } from 'react'
import PropTypes from "prop-types";

// import '../../assets/css/all.min.css';
// import '../../assets/css/icheck-bootstrap.min.css';
// import '../../assets/css/adminlte.min.css';
// import '../../assets/css/tempusdominus-bootstrap-4.min.css';
// import '../../assets/css/jqvmap.min.css';

// import '../../assets/css/daterangepicker.css';
// import '../../assets/css/dataTables.bootstrap4.min.css';
// import '../../assets/css/responsive.bootstrap4.min.css';
// import './style.css';

import '../../assets/css/header_admin.css';
import '../../assets/css/sidebarmenu.css';
import '../../assets/css/style.css'; 
import '../../assets/css/responsive.css'; 

import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const deviceWidth = window.innerWidth > 650 ? true : false

export default function AuthLayout({ children }) {
    const [toggleSidebar, setToggleSidebar] = useState(deviceWidth);

    const sidebarAction = (val) => {
        setToggleSidebar(val);
    }

    return (
        <>
            
                <div className="wrapper-main">
                    <Header/> 
                    <Sidebar/> 
                    {children}
                    <Footer />
                </div> 
        </>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
};

