import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import DefaultLayout from "../_layouts/default";
import AuthLayout from "../_layouts/auth";
import ClientLayout from "../_layouts/client";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isClientPortal,
  isPrivateAdmin,
  isGrantAccess, // admin,team,client
  ...rest
}) {

  let checkTokenData = localStorage.getItem("userToken");  
  let checkToken = '';
  let loginRoleName = '';
 
  let signed;
  if(checkTokenData){
    checkToken = JSON.parse(checkTokenData);
    loginRoleName = checkToken.role; 
    if(checkToken){ 
      signed = checkToken.access_token ? true : false;
    }else{
      signed = false;
    }
}else{ 
  signed = false;
}
  
  let resetURL = window.location.pathname.split("/")[1];
  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */

  if (isPrivate && !signed) {
    return <Redirect to='/login' />;
  }

  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */

 let roleLayout = AuthLayout;
 if(loginRoleName === 'Super_Admin' || loginRoleName === 'Team_Member'){
    roleLayout = AuthLayout;  
    if (!isPrivate && signed) {
      return <Redirect to='/dashboard' />;
    } 
    if(loginRoleName === 'Team_Member'){
      if (isClientPortal && signed) {
        return <Redirect to='/dashboard' />;
      } 
      if (isPrivateAdmin && signed) {
        return <Redirect to='/dashboard' />;
      } 
    }
 }else if(loginRoleName === 'Client'){ 
    roleLayout = ClientLayout; 
    if (!isGrantAccess && signed) { 
      if (!isClientPortal && signed) { 
        return <Redirect to='/client-portla/dashboard'/>;
      }
    }
 }else{
  roleLayout = DefaultLayout; 
 }
  const Layout = signed ? roleLayout : DefaultLayout;

 

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false
};
