import React, { Component } from "react"; 
import sign from "../../assets/images/sign.png";
export default class PersonalCustomerToCustomerRelations extends Component {
  state = {
    name: "",
    role: "",
  };
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //console.log(checkToken.name,'d')
    this.setState({
      name: checkToken.name,
      role: checkToken.role,
    });
  }
  render() {
    const { name, role } = this.state;
    return (
      <> 
      <div className="wrapper wrappermax">
  <div className="mainheading2 text-center w100 m-0">
    Customer To Customer Relations
  </div>
  <div className="container mainArea" id="formpage">
    <div className="row">
      <div className="tablebox w100">
        <table className="table table-bordered table-responsive-sm">
          <thead>
            <tr>
              <th scope="col">ID No</th>
              <th scope="col">Related CLNO</th>
              <th scope="col">Related Client Name</th>
              <th scope="col">Kind of Relation</th>
              <th scope="col">Online Access</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td className="bggreen">Active</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

       </>
    );
  }
}
