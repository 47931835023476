import React, { Component } from 'react'

export default class Logout extends Component {

    async componentDidMount(){
        localStorage.removeItem("userToken");
        this.props.history.push('/login')
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
