import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
 

require('dotenv').config();
window.$URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
window.$ImageURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_LIVE_IMAGE_URL : process.env.REACT_APP_DEV_IMAGE_URL;
window.$DocumentURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_LIVE_DOCUMENT_URL : process.env.REACT_APP_DEV_DOCUMENT_URL;

 
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


