import React from 'react';
import { Router } from "react-router-dom";
import history from "./services/history";
import Routes from "./routes"; 
import './App.css'; 
// Firebase deps
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
 
function App() {
  return (
    <div> 
    <Router history={history}> 
      <Routes />
    </Router>
    </div>
  );
}

export default App;
