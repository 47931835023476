import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

export default class ForgotPassword extends Component {
  state = {
    email: "",

    emailError: "",

    successMessage: "",
    success: false,
    errorMessage: "",
    error: false,
    token: "",
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  validate = () => {
    this.setState({
      emailError: "",
    }); 
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    let emailError = "";

    if (this.state.email.length < 1) {
      emailError = "Please enter Email";
    } else if (!regex.test(this.state.email.trim())) {
      emailError = "Please enter a valid Email";
    }
    if (emailError) {
      this.setState({ emailError });
      return false;
    }

    this.setState({
      emailError,
    });
    return true;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      success: false,
      error: false,
      successMessage: "",
      token: "",

      errorMessage: "",
    });
    let checkValidation = this.validate();
    if (checkValidation) {
      const { email } = this.state;
      let loginData = {
        email: email,

        //"loginType": "customer"
      };
      await axios
        .post(`${window.$URL}reset-password`, loginData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success) {
              console.log(response.data.success);

              this.setState(
                {
                  success: true,
                  successMessage: response.data.success,
                  token: response.data.passwordChangeToken,
                },
                () => {
                  //this.props.history.push(`${process.env.PUBLIC_URL}/reset-password/${this.state.token}`);
                }
              );
              setTimeout(
                () => this.props.history.push(`/login`), 
                3000
                );
            } else {
              //console.log("errorAmi",response.data.error);
              this.setState({
                error: true,
                errorMessage: response.data.error,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const { emailError, success, successMessage, errorMessage } = this.state;
    return (
      <div className="main-container">
        <div className="loginarea">
          <div className="logo">
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          </div>
          <h4>Please insert Email Address</h4>
          {success ? (
            <p style={{ color: "green", fontSize: 15 }}> {successMessage} </p>
          ) : null}
          {errorMessage ? (
                  <span style={{ color: "red", fontSize: 15 }}> {errorMessage} </span>
                ) : null
          }
          {/* {
                                        error ?
                                        <p style={{color:'red', fontSize:18}}> {errorMessage} </p>
                                        :
                                        null
                                    } */}
          <form onSubmit={this.onSubmit}>
            <div className="logininner d-flex justify-content-center row">
              <div className="form-group input-group">
                <label className="has-float-label m-0" for="lgnForm_UserName">
                  <i className="fa fa-envelope input-group-addon"></i>
                </label>

                <input
                  type="email"
                  placeholder="Email Address"
                  className="form-control"
                  name="email"
                  onChange={this.onChange}
                />
                {emailError ? (
                  <p class="error-login ml-1" style={{ color: "red" }}> {emailError} </p>
                ) : null}
                
              </div>
              {/* <h4>Legal Information</h4> */}

              {/* <div className="row mtnew align-items-center h-100">
                <div className="col-4">
                  <h5 className="font-weight-bold">Captcha Challenge</h5>
                </div>
                <div className="col-8">
                  <img
                    src="assets/images/download.png"
                    alt="Captcha"
                    className="img-fluid"
                  />
                </div>
              </div> */}
              {/* <div className="form-group input-group mtnew">
                <input
                  className="form-control"
                  id="lgnForm_Captcha"
                  type="email"
                  value=""
                />
                <label className="has-float-label m-0" for="lgnForm_Captcha">
                  <i className="fa fa-refresh input-group-addon"></i>
                </label>
              </div> */}
              <input
                type="submit"
                name="lgnForm:LoginButton"
                value="Next"
                id="lgnForm_LoginButton"
              />
            </div>
          </form>
           <Link to="/login">Back To Login?</Link>  
        </div>
      </div>
    );
  }
}
