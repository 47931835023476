import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from "./RightMenu"
import TopMenu from "./TopMenu" 
export default class clientRelationDetails extends Component {


    state = {
        document_type: '',
        drivers_licence_number: '',
        issued_date: '',
        country_issued: '',
        location_issued: '',

        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token:'',
        

        validationErrors:{
            
            relatedClientNoError:'',
            relationError:'',
            percentError:'',
            narrativeError:''
      },
      showLicenseNumber:false,
      client_no:'',
      clientNumbers:[],
      relatedClientNo:'',
      relation:'',
      percent:'',
      narrative:''





    }
    async componentDidMount() {
        
        
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        //console.log(checkToken.name,'d')
        let accessToken = checkToken.access_token;
        this.setState({
            name: checkToken.name,
            role: checkToken.role,
            token:accessToken
        })

          //get listing
          await axios.get(`${window.$URL}client/client-specific-relation/${this.props.match.params.clientRelationId}`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
          .then(response => {
              console.log(response.data.data, 'response by edit')
              if(response.data.status){ 
                this.setState({
                    client_no: response.data.data.client_no,
                    related_client_no: response.data.data.related_client_no,
                    relation: response.data.data.relation,
                    narrative: response.data.data.narrative,
                    percent: response.data.data.percent,
                    country: response.data.data.country,
                    
                }) 
            }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing
        }
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
            console.log('someerror',this.props.match.params.clientId)
        } 
    } 
    render() {
        const { 
            document_typeError,
            drivers_licence_numberError,
            issued_dateError,
            country_issuedError,
            location_issuedError, 
        } = this.state;
        return ( 
            <div class="wrapper">
            <ToastContainer/> 
            <div class="container newContainer mainArea pt-0" id="formpage">
              <div class="row justify-content-center"> 
                <div class="lefeArea col-md-9 p-0">
                  <h3 class="heading">Create A New Relation Mervi Andersson</h3>
                  <TopMenu clientId={ this.props.match.params.clientMasterId }activeMenu="client_relations"/> 
                  <div class="content-wrap">
                    <div class="row justify-content-center"> 
                      <div class="col-md-6">
                        <h4 class="smallheading mb-0">Client Relation</h4>
                        <div class="tablebox">
                          <form>
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Client No</label>
                              <div class="col-sm-8">
                                <input type="text" class="form-control" name="client_no" id="inputEmail3" placeholder="Client No" value={this.state.client_no} disabled/>
                              </div>
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Related Client No <sup>*</sup></label>
                              <div class="col-sm-8">
                                <select class="form-control" name="relatedClientNo" id="exampleFormControlSelect1" readOnly>
                                  <option value={this.state.related_client_no} >{this.state.related_client_no}</option>                        
                                </select>
                              </div> 
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Relation <sup>*</sup></label>
                              <div class="col-sm-8">
                                <select class="form-control"  name="relation"  id="exampleFormControlSelect1" value={this.state.relation} readOnly>
                                  <option>Select Relation</option>
                                  <option>Signatory</option>
                                  <option>Online Banking</option>
                                  <option>Contact Person</option>
                                  <option>Father</option>
                                  <option>Mother</option>
                                  <option>Son</option>
                                  <option>Daughter</option>
                                  <option>Wife</option>
                                  <option>Husband</option>
                                  <option>Director</option>
                                  <option>Intermediary</option>
                                  <option>Nominee Shareholder</option>
                                  <option>Secretary</option>
                                  <option>UBBO</option> 
                                </select> 
                              </div>
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Narrative</label>
                              <div class="col-sm-8">
                                <input type="text" name="narrative" value={this.state.narrative} class="form-control" id="inputEmail3" placeholder="Narrative" readOnly/> 
                              </div> 
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Percent</label>
                              <div class="col-sm-8">
                                <input type="text" value={this.state.percent} name="percent" class="form-control" id="inputEmail3" placeholder="Percent" readOnly />
                                
                              </div>
                            </div> 
                            <div class="form-group row text-center mt-2 ml-auto">
                              <div class="col-sm-12">
                                <div class="btnwrp">
                                  <Link
                                      className="btn btn-primary bluebg"
                                      to={{
                                      pathname: `/client/client-relation/${this.props.match.params.clientMasterId}`, 
                                      clientId:this.props.match.params.clientMasterId,
                                      state: {
                                          clientId: this.props.match.params.clientMasterId  
                                      }
                                      }}
                                  >
                                  Back
                                  </Link> 
                                    <Link  className="btn btn-primary bluebg" to={{ 
                                        pathname: `/client/edit-client-relation/${this.props.match.params.clientMasterId}/${this.props.match.params.clientRelationId}`,
                                        state: {
                                            clientId: this.props.match.params.clientMasterId,
                                            id: this.props.match.params.clientOnlineAccessId, 

                                        }
                                    }}>
                                    Modify</Link>
                              </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        </div>
                    </div>
                 </div>
                </div> 
                  <RightMenu clientId={ this.props.match.params.clientMasterId } activeMenu="client_relations"/>    
              </div>
            </div>
          </div> 
        );
    }
}
