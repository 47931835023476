import React, { Component } from "react";
import sign from "../../assets/images/sign.png";
import axios from "axios";
import db from '../../firebase.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default class ContactSupport extends Component {
  state = {
    name: "",
    role: "",
    token:'',
    message_category:'',
    subject:'',
    message:'',
    message_document:'',
    validationErrors:{
      categoryError: '',
      subjectError: '',
      messageError: '', 
    },
    writeError:'',
    btnLaoder:false
  };
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    let accessToken = checkToken.access_token;
    this.setState({
      name: checkToken.name,
      role: checkToken.role,
      token:accessToken
    });
  }
  onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }
  validate = () => {
    this.setState({ 
      categoryError: '',
      subjectError: '',
      messageError: '',
    }); 
    let formvalidateStatus = true;
    if (this.state.message_category.length < 1) {
        this.setState(Object.assign(this.state.validationErrors,{categoryError:'Please Select Category'}));
        formvalidateStatus = false;
    }else{ 
        this.setState(Object.assign(this.state.validationErrors,{categoryError:''}));
    }

    if (this.state.subject.length < 1) {  
        this.setState(Object.assign(this.state.validationErrors,{subjectError:'Please enter message subject'}));
         formvalidateStatus = false;
    }else{
        this.setState(Object.assign(this.state.validationErrors,{subjectError:''}));
    } 
    if (this.state.message.length < 1) {  
        this.setState(Object.assign(this.state.validationErrors,{messageError:'Please enter message'}));
         formvalidateStatus = false;
    }else{
        this.setState(Object.assign(this.state.validationErrors,{messageError:''}));
    } 
 
    return formvalidateStatus
  }
  handleProfileImageChange = (e) => {
    e.preventDefault();
    const img = e.target.files[0]; 
    this.setState({
        //message_document: URL.createObjectURL(img),
        message_document: img,
    })
}
  onSubmit = (e) => {
    e.preventDefault();
    // alert('gii');return false;
    const { address1, address2, zip_code, city, country,token } = this.state; 
    const isValid = this.validate();
    //console.log(isValid,'isValid')
    if (isValid) {
        this.setState({btnLaoder:true});
        //alert('pk');
        let messageParma = {
            message_category:this.state.message_category,
            subject:this.state.subject,
            message:this.state.message  ,
        }
        var bodyLogoFormData = new FormData();
        bodyLogoFormData.set('message_category', this.state.message_category);
        bodyLogoFormData.set('subject', this.state.subject);
        bodyLogoFormData.set('message', this.state.message); 
        if(this.state.message_document !== null){  
          bodyLogoFormData.append('document_image', this.state.message_document);
        } 
            axios({
              method: 'post',
              url: `${window.$URL}support-ticket/add`,
              data: bodyLogoFormData,
              headers: { 'Content-Type': 'multipart/form-data' ,
                      "Authorization": `Bearer ${token}`}  
            })
            .then(response => {
                console.log(response, 'response')
                if (response.data.status) {
                    try {
                      let ticketReplaySlug = 'tickets/'+response.data.ticket_no+'/messages'; 
                      let ticketSlug = 'tickets/'+response.data.ticket_no; 
                      let messageBy ='admin'; 
                      if(this.state.role === 'Client'){
                          messageBy =  'agent'; 
                      } 
                      let updates = {};
                    updates['tickets/'+response.data.ticket_no+'/status'] = 'Pending';  
                    updates['tickets/'+response.data.ticket_no+'/status_message'] = 'New Support Request-'+response.data.ticket_no; 
                    db.ref().update(updates);
                    db.ref(ticketReplaySlug).push({
                          message: this.state.message,
                          timestamp: Date.now(), 
                          sender_name:this.state.name,
                          messageBy:messageBy,
                      });
                        
                      this.setState({ writeError:"save" });
                    } catch (error) {
                      this.setState({ writeError: error.message });
                    }
                   
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }); 
                    this.componentDidMount(); 
                    this.props.history.push('/support-tickets');
                } else {
                    toast.error('Something Went Wrong', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }); 
                }
                this.setState({btnLaoder:false});
            })
            .catch(error => {
                console.error(error.data);
                this.setState({btnLaoder:false});
            })
    }
}
  render() {
    const { name, role } = this.state;
    return (
      <>
        <div className="wrapper wrappermax">
          <div className="mainheading2 text-center w100 m-0"> Contact/Support</div>
          {/* <ul className="nav leftmenuclient leftmenuclient3">
            <li className="nav-item">
              <a className="nav-link active" href="contact-support.html">
                Contact/Support
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="received-message.html">
                Received Message
              </a>
            </li>
            <li className="nav-item bordernone">
              <a className="nav-link " href="send-message.html">
                Send Message
              </a>
            </li>
          </ul> */}
          <div className="container mainArea" id="formpage">
            <div className="lefeArea col-md-12">
                    <h4 className="smallheading mb-0">Write Message</h4>
              <div className="tablebox">
                <form onSubmit={this.onSubmit}> 
                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Message Category<sup>*</sup>
                    </label>
                    <div className="col-sm-7">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        name="message_category" onChange={this.onChange}
                      >
                        <option>Select Message Category</option>
                        <option value="General Question">General Question</option>
                        <option value="Transfers">Transfers</option>
                        <option value="Products">Products</option>
                        <option value="Complaints">Complaints</option>
                      </select>
                      <p className="error">{this.state.validationErrors.categoryError}</p>
                    </div>
                  </div>  
                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Subject<sup>*</sup>
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="text"
                        name="subject" onChange={this.onChange}
                        className="form-control widthauto"
                        id="inputEmail3"
                        placeholder=""
                      />
                       <p className="error">{this.state.validationErrors.subjectError}</p>
                    </div>
                  </div>
                  <div className="form-group heightauto row align-items-start">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Message<sup>*</sup>
                    </label>
                    <div className="col-sm-7">
                      <textarea
                         name="message" onChange={this.onChange}
                        className="form-control w-100 minheight"
                        id="exampleFormControlTextarea1"
                      ></textarea>
                       <p className="error errorm">{this.state.validationErrors.messageError}</p>
                    </div>
                  </div>
                  <div className="form-group row height0"></div>
                    <h4 className="smallheading">Attachments Upload</h4>
                
                  <div className="form-group row mt-0">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      File Upload 
                    </label>
                    <div className="col-sm-7">
                      <input type="file" className="form-control"  onChange={(event) => this.handleProfileImageChange(event)}/>
                    </div>
                  </div> 
                <div className="form-group row">
                  <div className="col-sm-12 wm100">
                    <div className="btnwrp">
                      <button type="submit" className="btn btn-primary">
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                      <div className="buttonloader"><div class={`loader_wrap ${ this.state.btnLaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
                </form>
              </div> 
            </div>
          </div>
        </div>
      </>
    );
  }
}
