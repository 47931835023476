import React, { Component } from 'react'
import axios from 'axios';
export default class Register extends Component {
    constructor(props){
        super(props)
        this.state={
            fname:"",
            lname:"",
            email:"",
            phone:"",
            password:"",
            fnameError:'',
            lnameError:'',
            emailError:'',
            phoneError:'',
            passwordError:'',

        }
    }
    state = this.state

    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value})
    }

    validate = () => {
        let fnameError = '';
        let lnameError='';
        let emailError = '';
        let phoneError = '';
        let passwordError = '';

        if(this.state.fname === ''){
            fnameError = "Please Enter Your First Name"
        }
        if(fnameError){
            this.setState({fnameError})
            return false
        }

        if(this.state.lname === ''){
            lnameError = "Please Enter Your Last Name"
        }
        if(lnameError){
            this.setState({lnameError})
            return false
        }
        if(!this.state.email.includes('@')){
            emailError = "InValid Email"
        }
        if(this.state.email === ''){
            emailError = "Please Enter Email"
        }
        if(emailError){
            this.setState({emailError})
            return false
        }
        if(this.state.password === ''){
            passwordError = "Please Enter Password"
        }

        if(passwordError){
            this.setState({passwordError})
        }
        if(this.state.phone === '' || this.state.phone === null ){
            phoneError = "Please Enter Your Phone Number"
        }else{
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(this.state.phone)) {
              phoneError = "Please enter only number.";
            }else if(this.state.phone.length !== 10){
              phoneError = "Please enter valid phone number.";
            }
        }

        if(phoneError){
            this.setState({phoneError})
            return false
        }

        return true
    }
    onSubmit = (e) => {
        e.preventDefault();
        const {fname,lname,email,password,phone} = this.state;
        const UserType = 'Seller';
        const isValid = this.validate();
        if(isValid){
            axios.post(`${window.$URL}user/signup`,{fname,lname,email,password,phone,UserType})
            .then(response => {
                console.log(response.data)
            })
            .catch(error => {
                console.error(error.data)
            })
        }
    }


    render() {
        return (
            <div>
                <div className="login-box">
                    {}
                    <div className="login-logo">
                        <img src={require('../../assets/images/logo.png')} alt="AdminLTE Logo" className="brand-image " style={{ opacity: '.8',width:'230px' }} />
                    </div>
                    {/* /.login-logo */}
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Register As A Seller</p>
                            <form onSubmit={this.onSubmit}>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="First Name" name="fname" onChange={this.onChange} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-user" />
                                        </div>
                                    </div>
                                </div>
                                {this.state.fnameError ? (<div style={{color:'red',fontSize:12,marginTop:-10}}>{this.state.fnameError}</div>) : null }
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Last Name" name="lname" onChange={this.onChange} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-user" />
                                        </div>
                                    </div>
                                </div>
                                {this.state.lnameError ? (<div style={{color:'red',fontSize:12}}>{this.state.lnameError}</div>) : null }
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Email" name="email" onChange={this.onChange} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                {this.state.emailError ? (<div style={{color:'red',fontSize:12}}>{this.state.emailError}</div>) : null }
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={this.onChange} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                {this.state.passwordError ? (<div style={{color:'red',fontSize:12}}>{this.state.passwordError}</div>) : null }
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Phone" name="phone" onChange={this.onChange} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-phone" />
                                        </div>
                                    </div>
                                </div>
                                {this.state.phoneError ? (<div style={{color:'red',fontSize:12}}>{this.state.phoneError}</div>) : null }
                                <div className="row">
                                    <div className="col-8">
                                    <p className="mb-2 mt-2">
                                        <a href="/login">Already Have Account?</a>
                                    </p>
                                    </div>
                                    {/* /.col */}
                                    <div className="col-4">
                                        <button type="submit" className="btn btn-primary btn-block">Register</button>
                                    </div>
                                    {/* /.col */}
                                </div>
                            </form>
                            {/*<div className="social-auth-links text-center mb-3">
                                <p>- OR -</p>
                                <a href="#/" className="btn btn-block btn-primary">
                                    <i className="fab fa-facebook mr-2" /> Sign in using Facebook
                                </a>
                                <a href="#/" className="btn btn-block btn-danger">
                                    <i className="fab fa-google-plus mr-2" /> Sign in using Google+
                                </a>
                                </div>*/}
                            {/* /.social-auth-links */}
                     
                            
                        </div>
                        {/* /.login-card-body */}
                    </div>
                </div>
            </div>
        )
    }
}
