import firebase from 'firebase'
//import firebase from "firebase/app"
import "firebase/firestore"
const config = {
    apiKey: "AIzaSyCxw6yEL1O4wqs0083Qb_ehBBcmc_5ZK08", 
    authDomain: "fruxbank-40614.firebaseapp.com",
    projectId: "fruxbank-40614",
    storageBucket: "fruxbank-40614.appspot.com",
    messagingSenderId: "523844944153",
    appId: "1:523844944153:web:15d9faaa458e4ac3514588",
    measurementId: "G-W4E64476JZ",  
    databaseURL: "https://fruxbank-40614-default-rtdb.firebaseio.com/"
  };
  firebase.initializeApp(config); 
const db = firebase.database(); 
export default db; 