import React, { Component } from 'react'; 
import { Link } from 'react-router-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
export default class NavbarMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name : '',
            role : '',
            pageName: window.location.pathname.split("/"),
            showSidebar:'',
            hamburger:'is-closed',
            sidebaroverlay:'none'
        }
        
    }
  async componentDidMount(){
      let checkTokenData = localStorage.getItem("userToken");
      let checkToken = JSON.parse(checkTokenData);
      //console.log(checkToken.name,'d')
      this.setState({
        name:checkToken.name,
        role:checkToken.role
      })
  }
   
  render() {
      const {pageName} = this.state;
      return (
       <div>
        <nav id="navbarm" className="header navbar navbar-expand-lg ">
          <div className="container-fluid"> 
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav"> 
              <li className="nav-item dropdown"> 
                <Link className="nav-link" to="/client-portla/dashboard"><i class="fa fa-dashboard"></i> Dashboard</Link> 
              </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-user-o"></i>
                    Personal
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">  
                    <Link className="dropdown-item" to="/client-portla/personal-information">Information</Link> 
                    <Link className="dropdown-item" to="/client-portla/personal-address">Addresses</Link>
                    <Link className="dropdown-item" to="/client-portla/personal-identifications">Identifications</Link>  
                  </div> 
                </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-book"></i>
                  Accounts
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/client-portla/personal-accounts">Accounts</Link>
                <Link className="dropdown-item" to="/client-portla/personal-open-new-account">Open New Account</Link>  
                <Link className="dropdown-item" to="/client-portla/myTransactions">Transaction History</Link>  
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-exchange"></i>
                  Transfers
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/transactions">Tranferster - To Connected Accounts</Link>
                
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link" to="/client-portla/statements">
                  <i className="fa fa-pie-chart" aria-hidden="true"></i>
                  Statements
                  </Link>
                </li> 
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-podcast"></i>
                  Services
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/client-portla/contact-support">Contact/Support</Link>
                  <Link className="dropdown-item" to="/support-tickets">My Tickets</Link>
                  {/* <Link className="dropdown-item" to="/client-portla/process-password-change">Change Password</Link> */}
                </div>
              </li> 
            </ul> 
          </div>
          </div> 
      </nav>
      </div> 
      )
  }
}

