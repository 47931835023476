import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import CurrencyFormat from 'react-currency-format';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
 
export default class Transaction  extends Component {

    constructor(props) {
        super(props); 
          this.state = {
            accountType:'',  
            transaction_type:'01',  
            accountDebit: {
              'debit_account_id'    : '',
              'debit_currency'      : '',
              'debit_account'       :'', 
              'debit_amount'       : '',
              'debit_value_date'    :moment().format('Y-MM-DD'),
              'debit_details'       : '',
              'debit_description'   : '', 
            },
            accountCredit: {
                'credit_account_id'     : '',
                'credit_currency'      : '',
                'credit_account'         :'', 
                'credit_amount'        : '',
                'credit_value_date'     : moment().format('Y-MM-DD'),
                'credit_details'        : '',
                'credit_description'    : '', 
            },
            validationDebitErrors: {
                'debit_account_id'  : '',
                'debit_amount'     : '',
                'debit_value_date'  : '',
                'debit_details'     : '',
                'debit_description' : '', 
            },
            validationCreditErrors: {
                'credit_account_id'     : '',
                'credit_amount'        : '',
                'credit_value_date'     : '',
                'credit_details'        : '',
                'credit_description'    : '',
                'scheduleTypeError'         :'',
                'interestError'         :'',
                'paymentTypeError'      :'',
            },
            accountSearch:{
                'search_account_parma':'',
                'search_account_number':'',
                'search_client_number':'',
                'search_account_type' :''
            },
            payment_type:'',
            interest:'',
            start_date:moment().format('Y-MM-DD'),
            end_date:moment().add(1, 'd').add(1, 'M').format('Y-MM-DD'),
            minEndDate:moment().add(1, 'd').add(1, 'M').format('Y-MM-DD'),
            minStartDate:moment().add(1, 'd').format('Y-MM-DD'),
            schedule_type:'',
            transactionTypes:[], 
            transactionAccounts:[],
            transactionRelatedAccounts:[],
            relatedAccounts:[],
            debitAccountSearch:false,
            creditAccountSearch:false,
            creditAmountReadOnly:true,
            debitAmountReadOnly:false,
            creditDateReadOnly:true,
            role:'',
            saveloader:false,
            searchLoader:false,
            checkedAll:false, 
            showRelatedClients:false,
            accountTypes:[],
            amountLoader:false
        };
        this.handleChange = this.handleChange.bind(this);  
     
    }
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //console.log(checkToken.name,'d')
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            });
            this.getConfig();
            //this.getClientAccounts();
        }
        
        
    }
    async getConfig()
    { 
            let clientId=this.props.match.params.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
                
                let accessToken = checkToken.access_token; 
                //get listing
                await axios.get(`${window.$URL}getConfigs?transaction_types=true&account_types=true`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => {
                    console.log(response.data.data.currency, 'response by edit')
                    if (response.data.status) { 
                        if(response.data.data.transaction_types){
                            this.setState({transactionTypes:response.data.data.transaction_types}); 
                        }
                        if(response.data.data.account_types){
                            this.setState({accountTypes:response.data.data.account_types}); 
                        } 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

            } 
        
    } 
    onChangeDebit = (e) => {
        //e.preventDefault();
        console.log(e.target.value,'value')
        const { name, value } = e.target; 
         const { accountDebit } = this.state;
         this.setState({
            accountDebit: {
                 ...accountDebit,
                 [name]: value
             }
         },this.validateDebit);
         
         if(this.state.transaction_type === "01"){
            if(name === 'debit_amount'){ 
                //this.setState(Object.assign(this.state.accountCredit,{credit_amount:value}));
            }else if(name === 'debit_value_date'){
                this.setState(Object.assign(this.state.accountCredit,{credit_value_date:value})); 
            }
         }
         if(this.state.transaction_type === '05'){
            this.setState({ 
                [name]: value 
            },this.validateCredit); 
            if(name === 'start_date'){
                this.setState({ 
                    debitAmountReadOnly:false,
                    minEndDate:moment(moment(value)).add(1, 'M').format('Y-MM-DD'),
                    end_date:moment(moment(value)).add(1, 'M').format('Y-MM-DD')
                });
            } 
        } 
        if(this.state.transaction_type === "01"){
            if (this.state.accountCredit.credit_account_id.length < 1) {
                if(name === 'debit_amount'){  
                this.setState(Object.assign(this.state.validationCreditErrors,{credit_account_id:'Please Select A Account'})); 
                }else{
                    this.setState(Object.assign(this.state.validationCreditErrors,{credit_account_id:''}));
                }
            }
            if(this.state.accountDebit.debit_account_id.length < 1 && this.state.accountCredit.credit_account_id.length < 1){
                this.setState({
                    accountDebit: {
                         ...accountDebit,
                         [name]: 0.00
                     }
                 },this.validateDebit);
            }else{
                setTimeout(
                    function() {
                        this.currancyConvert();
                    }
                    .bind(this),
                    10
                ); 
               
            }
        }
    } 
    currancyConvert = () => { 
        const { accountCredit,accountDebit } = this.state;
        this.setState({amountLoader:true});
         axios.get(`https://api.exchangerate.host/convert`,{
            params: {  
                "from": accountDebit.debit_currency, 
                "to"  : accountCredit.credit_currency,
                "amount":accountDebit.debit_amount,
                "places":2
            }, 
        })
        .then(response => {
            console.log("convert"+response.data.result); 
            this.setState({
                accountCredit: {
                     ...accountCredit,
                     credit_amount: response.data.result
                 }
             },this.validateDebit);
             this.setState({amountLoader:false});
            
        })
        .catch(error => {
            console.error("asd"+error.message);
            this.setState({amountLoader:false});
            
        }) 
    }
    onChangeCredit = (e) => {
       // e.preventDefault();
        console.log(e.target.value,'value')
        const { name, value } = e.target; 
         const { accountCredit } = this.state;
         this.setState({
            accountCredit: {
                 ...accountCredit,
                 [name]: value
             }
         },this.validateCredit);
        
         
    } 
    onChangeAccountSearch = (e) => {
        //e.preventDefault();
        console.log(e.target.value,'value')
        const { name, value } = e.target; 
        let searchType  = e.target.getAttribute("data-search");
         const { accountSearch } = this.state;
         this.setState({
            accountSearch: {
                 ...accountSearch,
                 [name]: value
             }
         }); 
         setTimeout(
            function() {
                this.getClientAccounts(searchType);
            }
            .bind(this),
            10
        );  
         
    } 
    
    validateDebit = () => {
        const{ accountDebit } = this.state; 
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        let formvalidateStatus = true;
        if(this.state.transaction_type !== "03"){
            if (accountDebit.debit_account_id.length < 1) { 
                this.setState(Object.assign(this.state.validationDebitErrors,{debit_account_id:'Please Select A Account'}));
                formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationDebitErrors,{debit_account_id:''}));
            } 
            if(this.state.transaction_type !== "05" || (this.state.transaction_type == "05" && this.state.payment_type == '1')){
                if (accountDebit.debit_amount.length < 1) { 
                    this.setState(Object.assign(this.state.validationDebitErrors,{debit_amount:'Please Enter Debit Amout'}));
                    formvalidateStatus = false;
                }else if (!floatRegExp.test(accountDebit.debit_amount)){
                    this.setState(Object.assign(this.state.validationDebitErrors,{debit_amount:'Please Enter Valid Amount'}));
                    formvalidateStatus = false;
                }else if (accountDebit.debit_amount < 0){
                    this.setState(Object.assign(this.state.validationDebitErrors,{debit_amount:'Amount Must be Greater Than 0'}));
                    formvalidateStatus = false;
                }else{
                    this.setState(Object.assign(this.state.validationDebitErrors,{debit_amount:''}));
                }
            }
            if(this.state.transaction_type == "05"){      
                    if (this.state.schedule_type.length < 1) { 
                        this.setState(Object.assign(this.state.validationCreditErrors,{scheduleTypeError:'Please Select Recurring Type'}));
                        formvalidateStatus = false;
                    }else{
                        this.setState(Object.assign(this.state.validationCreditErrors,{scheduleTypeError:''}));
                    }
                    if(this.state.transaction_type == "05" && this.state.payment_type == '2'){
                        if (this.state.interest.length < 1) { 
                            this.setState(Object.assign(this.state.validationCreditErrors,{interestError:'Please Enter Interest.'}));
                            formvalidateStatus = false;
                        }else if (!floatRegExp.test(this.state.interest)){
                            this.setState(Object.assign(this.state.validationCreditErrors,{interestError:'Please Enter Valid Amount'}));
                            formvalidateStatus = false;
                        }else if (this.state.interest > 100){
                            this.setState(Object.assign(this.state.validationCreditErrors,{interestError:'Max 100 interst allow.'}));
                            formvalidateStatus = false;
                        }else if (this.state.interest < 0.1){
                            this.setState(Object.assign(this.state.validationCreditErrors,{interestError:'Interest Must be Greater Than 0'}));
                            formvalidateStatus = false;
                        }else{
                            this.setState(Object.assign(this.state.validationCreditErrors,{interestError:''}));
                        }
                    }
                
            }
            if (accountDebit.debit_value_date.length < 1) { 
                this.setState(Object.assign(this.state.validationDebitErrors,{debit_value_date:'Please Select A Date'}));
                formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationDebitErrors,{debit_value_date:''}));
            }
        }  

         return formvalidateStatus
     }
     validateCredit = () => {
        const{ accountCredit } = this.state; 
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        let formvalidateStatus = true;
        if(this.state.transaction_type !== "02" && this.state.transaction_type !== "05"){
            if (accountCredit.credit_account_id.length < 1) { 
                this.setState(Object.assign(this.state.validationCreditErrors,{credit_account_id:'Please Select A Account'}));
                formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationCreditErrors,{credit_account_id:''}));
            }
            if(this.state.transaction_type !== "05"){         
                if (accountCredit.credit_amount.length < 1) { 
                    this.setState(Object.assign(this.state.validationCreditErrors,{credit_amount:'Please Enter Credit Amout'}));
                    formvalidateStatus = false;
                }else if (!floatRegExp.test(accountCredit.credit_amount)){
                    this.setState(Object.assign(this.state.validationDebitErrors,{credit_amount:'Please Enter Valid Amount'}));
                    formvalidateStatus = false;
                }else if (accountCredit.credit_amount < 0){
                    this.setState(Object.assign(this.state.validationDebitErrors,{credit_amount:'Amount Must be Greater Than 0'}));
                    formvalidateStatus = false;
                }else{
                    this.setState(Object.assign(this.state.validationCreditErrors,{credit_amount:''}));
                }
            }
            
            if (accountCredit.credit_value_date.length < 1) { 
                this.setState(Object.assign(this.state.validationCreditErrors,{credit_value_date:'Please Select A Date'}));
                formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationCreditErrors,{credit_value_date:''}));
            }
        }
         return formvalidateStatus
     }
     onChange = (e) => { 
        console.log(e.target.value,'value')
        const { name, value } = e.target;  
         const { accountDebit } = this.state;
         this.setState({ 
                 [name]: value 
         }); 
         if(name === 'start_date'){
            this.setState({ 
                minEndDate:moment(moment(value)).add(1, 'M').format('Y-MM-DD'),
                end_date:moment(moment(value)).add(1, 'M').format('Y-MM-DD')
            });
         }
         if(name === 'interest'){
            this.validateCredit();
         } 
    }
    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const { transaction_type, currency, waive_fee, account_name, opening_details, remarks, mandate_instructions, notification, created_by,token } = this.state;


        this.setState({saveloader:true});
        const validateDebit = this.validateDebit();
        const validateCredit = this.validateCredit();
        //console.log(isValid,'isValid')
        if (validateDebit && validateCredit) {
            //alert('pk'); 
            let debit_value_date = '';
            let credit_value_date = '';
            let start_date = '';
            let end_date = '';
            if(this.state.accountDebit.debit_value_date){
                debit_value_date = moment(moment(this.state.accountDebit.debit_value_date)).format('Y-MM-DD');
            }
            if(this.state.accountCredit.credit_value_date){
                credit_value_date = moment(moment(this.state.accountCredit.credit_value_date)).format('Y-MM-DD');
            }
            if(this.state.start_date){
                start_date = moment(moment(this.state.start_date)).format('Y-MM-DD');
            }
            if(this.state.end_date){
                end_date = moment(moment(this.state.end_date)).format('Y-MM-DD');
            }
            let accountData =  { 
                'transaction_type'  : this.state.transaction_type,
                'debit_account_id'  : this.state.accountDebit.debit_account_id,
                'debit_amount'      : this.state.accountDebit.debit_amount,
                'debit_value_date'  : debit_value_date,
                'debit_details'     : this.state.accountDebit.debit_details,
                'debit_description' : this.state.accountDebit.debit_description,  
                'credit_account_id' : this.state.accountCredit.credit_account_id,
                'credit_amount'     : this.state.accountCredit.credit_amount,
                'credit_value_date' : credit_value_date,
                'credit_details'    : this.state.accountCredit.credit_details,
                'credit_description': this.state.accountCredit.credit_description, 
                'interest' : this.state.interest,
                'start_date'    : start_date,
                'end_date': end_date,
                'schedule_type':this.state.schedule_type,
                'relatedAccounts':this.state.transactionRelatedAccounts,
                'payment_type': this.state.payment_type
            }; 
            console.log(accountData)
            axios.post(`${window.$URL}transaction/create`, accountData,{ headers: {"Authorization" : `Bearer ${token}`}})
                .then(response => {
                    console.log(response, 'response')
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false
                        })
                        this.setState({  
                                accountDebit: {
                                  'debit_account_id'    : '',
                                  'debit_currency'      : '',
                                  'debit_account'       :'', 
                                  'debit_amount'       : '',
                                  'debit_value_date'    : moment().format('Y-MM-DD'),
                                  'debit_details'       : '',
                                  'debit_description'   : '', 
                                },
                                accountCredit: {
                                    'credit_account_id'     : '',
                                    'credit_currency'      : '',
                                    'credit_account'         :'', 
                                    'credit_amount'        : '',
                                    'credit_value_date'     : moment().format('Y-MM-DD'),
                                    'credit_details'        : '',
                                    'credit_description'    : '', 
                                },
                                validationDebitErrors: {
                                    'debit_account_id'  : '',
                                    'debit_amount'     : '',
                                    'debit_value_date'  : '',
                                    'debit_details'     : '',
                                    'debit_description' : '', 
                                },
                                validationCreditErrors: {
                                    'credit_account_id'     : '',
                                    'credit_amount'        : '',
                                    'credit_value_date'     : '',
                                    'credit_details'        : '',
                                    'credit_description'    : '',
                                },
                                accountSearch:{
                                    'search_account_parma':'',
                                    'search_account_number':'',
                                    'search_client_number':'',
                                    'search_account_type' :'',
                                }, 
                                saveloader:false,
                                interest:'',
                                start_date:moment().add(1, 'd').format('Y-MM-DD'),
                                end_date:moment().add(1, 'd').add(1, 'M').format('Y-MM-DD'),
                                minEndDate:moment().add(1, 'd').add(1, 'M').format('Y-MM-DD'),
                                minStartDate:moment().add(1, 'd').format('Y-MM-DD'),
                                schedule_type:'',
                            }
                        );
                        this.componentDidMount();
                    } else {
                        this.setState(Object.assign(this.state.validationDebitErrors,{debit_amount:response.data.balance_low}));
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong',
                            saveloader:false
                        })
                    }
                })
                .catch(error => {
                    console.error('==================='+error) 
                })
        }else{
            this.setState({saveloader:false});
        }
    }
    async getClientAccounts(accountType)
    { 
            let clientId=this.props.match.params.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
                this.setState({searchLoader:true});
                let accessToken = checkToken.access_token;
                let transactionType = this.state.transaction_type; 
                let selectedAccount = (accountType == 1) ? this.state.accountCredit.credit_account_id  : this.state.accountDebit.debit_account_id ;
                let parma = '&selected_account='+selectedAccount+'&account_type='+accountType;
                //get listing
               // await axios.get(`${window.$URL}getConfigs?transaction_account=`+transactionType+parma,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                await axios.get(`${window.$URL}getConfigs`,{
                    params: {
                        transaction_account :transactionType,
                        selected_account    : selectedAccount,
                        account_type        : accountType,
                        search_account_parma :this.state.accountSearch.search_account_parma,    
                        search_account_type :this.state.accountSearch.search_account_type,
                        // search_account_number :this.state.accountSearch.search_account_number,
                        // search_client_number:this.state.accountSearch.search_client_number,
                        

                    },
                    headers: { "Authorization": `Bearer ${accessToken}` }
                })
                .then(response => {
                   
                    if (response.data.status) { 
                        if(response.data.data.transaction_accounts){   
                            if(this.state.transaction_type !== '05'){ 
                                this.setState({transactionAccounts:response.data.data.transaction_accounts}); 
                                this.setState({searchLoader:false}); 
                            }else{ 
                                if(this.state.accountType === 2){
                                   
                                    var element = [];
                                    response.data.data.transaction_accounts.forEach(ac => {
                                        ac.isChecked = true;
                                        var parma = {
                                            isChecked : true,
                                            account_id:ac.account_id,
                                            account_number:ac.account
                                        };
                                        element.push(parma); 
                                        console.log("transactionRelatedAccounts"+ac);
                                    });
                                    this.setState({
                                        transactionRelatedAccounts:element,
                                        checkedAll:true,
                                        showRelatedClients:true,
                                        creditAccountSearch:false,
                                        accountSearch:{
                                            'search_account_parma':'',
                                            'search_account_number':'',
                                            'search_client_number':'',
                                            'search_account_type' :''
                                        },
                                    });      
                                }else{
                                    this.setState({transactionAccounts:response.data.data.transaction_accounts}); 
                                    this.setState({searchLoader:false});      
                                }
                            } 
                        }        
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

            }  
    }
    onChangeTransactionType = (e) => {
        e.preventDefault();
        console.log(e.target.value,'value')
        const { value } = e.target;  
       
         let accountDebit = {
            'debit_account_id'    : '',
            'debit_currency'      : '',
            'debit_account'       :'', 
            'debit_amount'       : '',
            'debit_value_date'    : moment().format('Y-MM-DD'),
            'debit_details'       : '',
            'debit_description'   : '', 
          };
          let accountCredit = {
              'credit_account_id'     : '',
              'credit_currency'      : '',
              'credit_account'         :'', 
              'credit_amount'        : '',
              'credit_value_date'     : moment().format('Y-MM-DD'),
              'credit_details'        : '',
              'credit_description'    : '', 
          };
          if(value !== "03"){
            if(value === "04"){
                this.setState({creditAmountReadOnly:true,debitAmountReadOnly:true,creditDateReadOnly:true});
            }else if(value === '05'){
                this.setState({creditAmountReadOnly:true,debitAmountReadOnly:false,creditDateReadOnly:true});
            }else{
                this.setState({creditAmountReadOnly:true,debitAmountReadOnly:false,creditDateReadOnly:true});
            } 
          }else {
            this.setState({creditAmountReadOnly:false,creditDateReadOnly:false,debitAmountReadOnly:false});
          }
          this.setState(Object.assign(this.state.accountCredit,accountCredit));
          this.setState(Object.assign(this.state.accountDebit,accountDebit)); 
          this.setState({transaction_type:value,debitAccountSearch:false,creditAccountSearch:false});
          
    }
    onClickDebitAccountModal = (e) => {
        e.preventDefault();
        this.setState({transactionAccounts:[],creditAccountSearch:false,debitAccountSearch:true,accountType:1});
        this.setState({ 
            accountSearch:{
                'search_account_parma':'',
                'search_account_number':'',
                'search_client_number':'',
                'search_account_type' :''
            },
        }); 
        setTimeout(
            function() {
                this.getClientAccounts(1);
            }
            .bind(this),
            10
        );  
        
    }
    onClickCreditAccountModal = (e) => {
        e.preventDefault();
        this.setState({transactionAccounts:[],creditAccountSearch:true,debitAccountSearch:false,accountType:2});
        this.setState({ 
            accountSearch:{
                'search_account_parma':'',
                'search_account_number':'',
                'search_client_number':'',
                'search_account_type' :''
            },
        }); 
        if(this.state.transaction_type == '05'){
            this.setState({creditAccountSearch:false});
        }
        setTimeout(
            function() {
                this.getClientAccounts(2);
            }
            .bind(this),
            10
        );  
    }
    onClickDebitAccountSelect = (e) => {
        e.preventDefault();
        let accountid  = e.target.getAttribute("data-account");
        const datatransactionAccounts = this.state.transactionAccounts;
        //const transactionAccount =  datatransactionAccounts.find( ({ account_id }) => account_id ===  accountid );
        const transactionAccount =  datatransactionAccounts.find(o => o.account_id == accountid);
       
        let debitparma = {
            'debit_account_id'  : transactionAccount.account_id,
            'debit_account'  : transactionAccount.account,
            'debit_currency'      : transactionAccount.currency,
            'debit_amount'      :0.00,
        };
        let creditparma = { 
            'credit_amount'      :0.00,
        };
        this.setState(Object.assign(this.state.accountDebit,debitparma));
        this.setState(Object.assign(this.state.accountCredit,creditparma));
        
        this.setState({debitAccountSearch:false});
        if(this.state.transaction_type === '05'){
            this.setState({accountType:2,creditAccountSearch:false});
            this.getClientAccounts(2);
        }
        this.validateDebit();
    }

    onClickCreditAccountSelect = (e) => {
        e.preventDefault();
        let accountid  = e.target.getAttribute("data-account");
        const datatransactionAccounts = this.state.transactionAccounts;
        //const transactionAccount =  datatransactionAccounts.find( ({ account_id }) => account_id ===  accountid );
        const transactionAccount =  datatransactionAccounts.find(o => o.account_id == accountid);
        let creditparma = {
            'credit_account_id'  : transactionAccount.account_id,
            'credit_account'  : transactionAccount.account,
            'credit_currency'      : transactionAccount.currency,
            'credit_amount'      :0.00,
        };
        let debitparma = { 
            'debit_amount'      :0.00,
        };
        this.setState(Object.assign(this.state.accountDebit,debitparma));
        this.setState(Object.assign(this.state.accountCredit,creditparma)); 
        this.setState({creditAccountSearch:false});

        // if(this.state.transaction_type === '05'){ 
        //     this.getClientRelatedAccounts(transactionAccount.account_id);
        // }
        this.validateCredit();
    }
    
    onClickDebitAccountModalClose = (e) => {
        this.setState({transactionAccounts:[],creditAccountSearch:false,debitAccountSearch:false});
    }
    onClickCreditAccountModalClose = (e) => {
        this.setState({transactionAccounts:[],creditAccountSearch:false,debitAccountSearch:false});
    } 
    async getClientRelatedAccounts(accountId)
    {
        let clientId=this.props.match.params.clientId
          
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
    
            let accessToken = checkToken.access_token; 
            await axios.get(`${window.$URL}client/relation-list`,{
                params: {
                    account_id :accountId, 
                },
                headers: { "Authorization": `Bearer ${accessToken}` }
            })
            .then(response => {
               
                if (response.data.status) {  
                    if(response.data.data){ 
                           console.log(response.data.data);
                                var element = [];
                                response.data.data.forEach(ac => {
                                    ac.isChecked = true;
                                    var parma = {
                                        isChecked : true,
                                        account_id:ac.account_id,
                                        account_number:ac.account_number
                                    };
                                    element.push(parma); 
                                    console.log("transactionRelatedAccounts"+ac);
                                });
                                this.setState({transactionRelatedAccounts:element,checkedAll:true,showRelatedClients:true}); 
                        
                      
                    } 
                }
            })
            .catch(error => {
                console.error(error.data)
            })
             //end listing

        }  
    }
    selectRelatedClientAccount = (e) => {
        const { value } = e.target;  
        let relatedClient  = e.target.getAttribute("data-raccount");
        const {relatedAccounts} = this.state; 
        var element = {};
        let selectedAccount = relatedAccounts.find(o => (o.related_client_id === relatedClient) ? true : false);
        var selectedAccountIndex = relatedAccounts.findIndex(function(c) { 
            return c.related_client_id == relatedClient; 
        });
        if(typeof selectedAccount != "undefined"){
           // console.log(relatedAccounts[selectedAccountIndex]); 
            relatedAccounts[selectedAccountIndex].account_id = value; 
            relatedAccounts[selectedAccountIndex].related_client_id = relatedClient; 
            
        }else{
            element.account_id = value;
            element.related_client_id = relatedClient;
            relatedAccounts.push(element); 
        } 
        this.setState({relatedAccounts:relatedAccounts});
    }
    handleChange(event) {   
        var isChecked = event.target.checked;  
        var item = event.target.value;  
        let transactionRelatedAccounts = this.state.transactionRelatedAccounts;
        transactionRelatedAccounts.forEach(account => { 
          if (account.account_id == item){
            account.isChecked = event.target.checked; 
          } 
        });
        this.setState({ transactionRelatedAccounts: transactionRelatedAccounts});
    }  
    handleAllChecked = event => {
        var isChecked = event.target.checked;  
        let transactionRelatedAccounts = this.state.transactionRelatedAccounts; 
        transactionRelatedAccounts.forEach(account => (account.isChecked = event.target.checked));
        this.setState({ transactionRelatedAccounts: transactionRelatedAccounts,checkedAll:isChecked });
      };
    handleDebitDateChange = (name,date) => { 
        var debitDate =   moment(moment(date));
        if(date){
            debitDate =   moment(moment(date));
        }else{
            debitDate = new Date();
        }

        const { accountDebit,accountCredit } = this.state;
         this.setState({
            accountDebit: {
                 ...accountDebit,
                 [name]: debitDate
             }
         },this.validateCredit);
         this.setState({
            accountCredit: {
                 ...accountCredit,
                 'credit_value_date': debitDate
             }
         },this.validateCredit);
    } 
    handleCreditDateChange = (name,date) => { 
        var creditDate =   moment(moment(date));
        if(date){
            creditDate =   moment(moment(date));
        }else{
            creditDate = new Date();
        }

        const { accountCredit } = this.state;
         this.setState({
            accountCredit: {
                 ...accountCredit,
                 [name]: creditDate
             }
         },this.validateCredit);
    }
    handleDateChange = (name,date) => {   
        if(date){ 
            this.setState({ 
                [name]: moment(moment(date)) 
            });
            this.setState({ 
                "end_date": moment(moment(date)).add(1, 'd').add(1, 'M').format('Y-MM-DD'),
                "minEndDate":moment(moment(date)).add(1, 'd').add(1, 'M').format('Y-MM-DD'),
            });
        } 
      }
    render() {
        const {            
            transactionTypes,transactionAccounts,transactionRelatedAccounts,accountTypes
        } = this.state; 
        let transactionTypeOptions = '';
        if(this.state.role === 'Client'){
            transactionTypeOptions = transactionTypes.map((item, index) =>{
                if(index == 0){
                    return (
                        <option value={item.value}>{item.name}</option>
                    )
                } 
            }, this); 
        }else{
            transactionTypeOptions = transactionTypes.map((item, index) =>{
                return (
                    <option value={item.value}>{item.name}</option>
                ) 
            }, this); 
        }
        // let transactionRelatedClientOptions = transactionRelatedAccounts.map((item, index) =>{
        //     let relatedClientAccountList = item.accounts.map((itemAccount, index) =>{
        //             return ( 
        //             <option value={itemAccount.account_id} >{itemAccount.account_number}</option>
        //             ) 
        //     }, this); 
        //     return (
        //         <div className="form-group"> 
        //             <label>{item.client_number}</label> 
        //             <select name="related_accounts[]" onChange={this.selectRelatedClientAccount} data-raccount={item.r_id} class="form-control">
        //                 <option value="0">Select Client Account</option>
        //                 {   relatedClientAccountList }
        //             </select> 
        //        </div>
        //     ) 
        // }, this);  
        let transactionRelatedClientCheckboxes = this.state.transactionRelatedAccounts.map((item, index) =>{ 
            return (
                <div className="client_account_checkbox">  
                  <input  
                    type="checkbox"  
                    value={item.account_id}  
                    onChange={this.handleChange}   
                    checked={item.isChecked} 
                  /> {item.account_number}
                </div>  
            ) 
        }, this);
        let transactionDebitAccountOptions = transactionAccounts.length > 0
        && transactionAccounts.map((item, i) => {
            return (
                <li key={i}><a href="javascript:void(0)" onClick={this.onClickDebitAccountSelect} data-account={item.account_id}>{item.account}</a></li>
            ) 
        }, this);

    let transactionCreditAccountOptions = transactionAccounts.length > 0
    && transactionAccounts.map((item, i) => {
        return (
            <li key={i}><a href="javascript:void(0)" onClick={this.onClickCreditAccountSelect} data-account={item.account_id}>{item.account}</a></li>
        ) 
    }, this);
    let accountTypeOptions = accountTypes.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        console.log(this.state.transactionRelatedAccounts);
        return (
            <div class="wrapper">
                 <ToastContainer />
                <div className="mainheading text-center mx-100 w-100">Transaction </div>
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center"> 
                        <div className="lefeArea col-md-12">
                            <div className="container">
                                <div className="lefeArea col-md-12"> 
                                        <form onSubmit={this.onSubmit}>
                                            <div className="tablebox">
                                                    <div className="form-group row mb-0">
                                                        <label for="inputEmail3" className="col-sm-4">Transaction Type</label>
                                                        <div className="col-sm-4"> 
                                                        <select name="transaction_type" className="form-control" id="exampleFormControlSelect1" onChange={this.onChangeTransactionType} > 
                                                             {transactionTypeOptions}
                                                            </select> 
                                                        </div>
                                                    </div> 
                                            </div> 
                                            <div className="row justify-content-left">
                                                { ((this.state.transaction_type !== "03")) &&
                                                    <div className="col-md-6">  
                                                        <h3 className="heading w100 mt-0 mb-3 debitarea">Debit</h3> 
                                                        <div className="tablebox">
                                                            <div className="form-group row">
                                                                <label for="inputEmail3" className="col-sm-4">Customer AC <sup>*</sup></label>
                                                                <div className="col-sm-8 d-flex align-items-start">
                                                                    <button type="button" className="btn btn-outline-secondary m-0" onClick={this.onClickDebitAccountModal}>Search</button>
                                                                    <input type="text" name="debit_account" onChange={this.onChangeDebit} value={this.state.accountDebit.debit_account} className="form-control" placeholder="000004-410-00" readOnly/>
                                                                    <p className="error">{this.state.validationDebitErrors.debit_account_id}</p>
                                                                    {this.state.debitAccountSearch &&
                                                                        <div className="accountbox-wrap">
                                                                            <div className="account_search_wrap">
                                                                                <i className="fa fa-times" aria-hidden="true" onClick={this.onClickDebitAccountModalClose}></i>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <input type="text" name="search_account_parma" data-search="1" onChange={this.onChangeAccountSearch} placeholder="Client name, Client Number, Account Number" className="search_input m-0"/> 
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <select class="form-control" name="search_account_type" onChange={this.onChangeAccountSearch}>
                                                                                            <option value="">Select Account Type</option>
                                                                                            {accountTypeOptions}
                                                                                        </select>
                                                                                    </div>
                                                                                </div> 
                                                                                {/* <input type="number" name="search_client_number"  onChange={this.onChangeAccountSearch} placeholder="Client Number:000000" className="search_input"/>
                                                                                <input type="number" name="search_account_number"  onChange={this.onChangeAccountSearch} placeholder="Account Number:00" className="search_input"/>
                                                                                <button type="button" className="btn btn-outline-secondary" onClick={this.onAccountSearch}>Search</button> */}
                                                                            </div>
                                                                                
                                                                            <div className="accountlist">
                                                                            <div class={`loader_wrap ${ this.state.searchLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div>
                                                                            <ul>
                                                                                {transactionDebitAccountOptions}
                                                                            </ul>
                                                                            </div>
                                                                        </div>
                                                                    } 
                                                                </div>
                                                            </div> 
                                                            { ((this.state.transaction_type == "05")) &&
                                                            <div className="form-group row">
                                                                <label for="inputEmail3" className="col-sm-4">Payment Type</label>
                                                                <div className="col-sm-8">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="radio" name="payment_type" id="payment_type_amount" value="1" onChange={this.onChangeDebit} checked={this.state.payment_type == '1'}/>
                                                                        <label class="form-check-label" for="payment_type_amount">
                                                                            Amount
                                                                        </label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="radio" name="payment_type" id="payment_type_percentage" value="2" onChange={this.onChangeDebit} checked={this.state.payment_type == '2'}/>
                                                                        <label class="form-check-label" for="payment_type_percentage">
                                                                            Percentage
                                                                        </label>
                                                                    </div>
                                                                    <p className="error">{this.state.validationCreditErrors.paymentTypeError}</p>
                                                                </div>
                                                            </div>
                                                            }
                                                             { ((this.state.transaction_type == "05" && this.state.payment_type == '1') || this.state.transaction_type !== "05") &&
                                                                    <div className="form-group row">
                                                                            <label for="inputEmail3" className="col-sm-4" wfd-id="54">Amount  <sup>*</sup></label>
                                                                                <div className="col-sm-8 d-flex">
                                                                                    <input type="text" className="form-control w-50"  placeholder="" disabled value={this.state.accountDebit.debit_currency}/>
                                                                                    <input type="text" name="debit_amount" value={this.state.accountDebit.debit_amount} onChange={this.onChangeDebit} className="form-control w100" placeholder="0.00" readOnly={this.state.debitAmountReadOnly}/><div className="buttonloader"><div class={`loader_wrap ${ this.state.amountLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                                                                    <p className="error">{this.state.validationDebitErrors.debit_amount}</p>
                                                                                </div>
                                                                    </div>
                                                            }
                                                            { this.state.transaction_type == '05' &&
                                                                <>
                                                                    { this.state.payment_type == '2' &&
                                                                        <div className="form-group row">
                                                                            <label for="inputEmail3" className="col-sm-4">Interest</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="number"  className="form-control"   name="interest" onChange={this.onChangeDebit} value={this.state.interest} placeholder="" />
                                                                                <p className="error">{this.state.validationCreditErrors.interestError}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group row">
                                                                        <label for="inputEmail3" className="col-sm-4">Schedule Type</label>
                                                                        <div className="col-sm-8">
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="schedule_type" id="schedule_type_recurring" value="1" onChange={this.onChangeDebit} checked={this.state.schedule_type == '1'}/>
                                                                                <label class="form-check-label" for="schedule_type_recurring">
                                                                                    Recurring
                                                                                </label>
                                                                                </div>
                                                                                <div class="form-check form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="schedule_type" id="schedule_type_onoff" value="2" onChange={this.onChangeDebit} checked={this.state.schedule_type == '2'}/>
                                                                                <label class="form-check-label" for="schedule_type_onoff">
                                                                                    One off
                                                                                </label>
                                                                            </div>
                                                                            <p className="error">{this.state.validationCreditErrors.scheduleTypeError}</p>
                                                                        </div>
                                                                    </div>
                                                                    { this.state.schedule_type &&
                                                                        <div className="form-group row">
                                                                            <label for="inputEmail3" className="col-sm-4">Schedule Date</label>
                                                                            <div className="col-sm-4 customdatesm">
                                                                            <span for="inputEmail3" className="">Start Date</span>
                                                                                <DatePicker minDate={new Date(moment(this.state.minStartDate))}  dateFormat="yyyy-MM-dd" className="form-control " value={ this.state.start_date } selected={this.state.start_date && new Date(this.state.start_date)} name="start_date"   onChange={(date) => {
                                                                                    this.handleDateChange('start_date',date); 
                                                                                }}  />
                                                                            </div>
                                                                            { this.state.schedule_type == 1 &&
                                                                                <div className="col-sm-4 customdatesm">
                                                                                    <span for="inputEmail3" className="">End Date</span> 
                                                                                    <DatePicker minDate={new Date(moment(this.state.minEndDate))}  dateFormat="yyyy-MM-dd" className="form-control " value={ this.state.end_date } selected={this.state.end_date && new Date(this.state.end_date)} name="end_date"   onChange={(date) => {
                                                                                    this.handleDateChange('end_date',date); 
                                                                                }}  />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }  
                                                                </>  
                                                            }
                                                            <div className="form-group row mt-5">
                                                                <label className="col-sm-4">Value Date<sup>*</sup></label>
                                                                <div className="col-sm-8 customdatesm"> 
                                                                <DatePicker  readOnly={this.state.debitDateReadOnly} minDate={new Date(moment())} dateFormat="yyyy-MM-dd" className="form-control " value={ this.state.accountDebit.debit_value_date } selected={this.state.accountDebit.debit_value_date && new Date(this.state.accountDebit.debit_value_date)} name="debit_value_date"   onChange={(date) => {
                                                                    this.handleDebitDateChange('debit_value_date',date); 
                                                                }}  />
                                                                     <p className="error">{this.state.validationDebitErrors.debit_value_date}</p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label for="inputEmail3" className="col-sm-4">Details </label>
                                                                <div className="col-sm-8">
                                                                    <input type="text" className="form-control"  onChange={this.onChangeDebit} name="debit_details" value={this.state.accountDebit.debit_details}  placeholder="" />
                                                                </div>
                                                            </div> 
                                                            <div className="form-group row">
                                                                <label for="inputEmail3" className="col-sm-4">Description </label>
                                                                <div className="col-sm-8">
                                                                    <input type="text"  className="form-control "  onChange={this.onChangeDebit} name="debit_description"  value={this.state.accountDebit.debit_description} placeholder="" />
                                                                </div>
                                                            </div> 
                                                        </div> 
                                                </div>
                                                }
                                                {((this.state.transaction_type !== "02")) &&
                                                <div className="col-md-6"> 
                                                        <h3 className="heading w100 mt-0 mb-3 craditarea">Credit</h3>
                                                        <div className="tablebox">
                                                            {this.state.transaction_type !== "05" &&
                                                            <div className="form-group row">
                                                                <label for="inputEmail3" className="col-sm-4">Customer AC</label>
                                                                <div className="col-sm-8 d-flex align-items-start"> 
                                                                        <button type="button" className="btn btn-outline-secondary mt-0" onClick={this.onClickCreditAccountModal}>Search</button>
                                                                    <input type="text" name="credit_account" onChange={this.onChangeCredit} value={this.state.accountCredit.credit_account} className="form-control w100" placeholder="000004-410-00" readOnly/>
                                                                         
                                                                    <p className="error">{this.state.validationCreditErrors.credit_account_id}</p>
                                                                    
                                                                    {this.state.creditAccountSearch && 
                                                                        <div className="accountbox-wrap">
                                                                            <div className="account_search_wrap">
                                                                                <i className="fa fa-times" aria-hidden="true" onClick={this.onClickCreditAccountModalClose}></i>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <input type="text" name="search_account_parma" data-search="2" onChange={this.onChangeAccountSearch} placeholder="Client name, Client Number, Account Number" className="search_input m-0"/> 
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <select class="form-control" name="search_account_type" onChange={this.onChangeAccountSearch}>
                                                                                            <option value="">Select Account Type</option>
                                                                                            {accountTypeOptions}
                                                                                        </select>
                                                                                    </div>
                                                                                </div> 
                                                                                {/* <input type="number" name="search_client_number"  onChange={this.onChangeAccountSearch} placeholder="Client Number:000000" className="search_input"/>
                                                                                <input type="number" name="search_account_number" onChange={this.onChangeAccountSearch} placeholder="Account Number:00" className="search_input"/>
                                                                                <button type="button" className="btn btn-outline-secondary">Search</button> */}
                                                                            </div>
                                                                                {/* <button type="button" className="btn btn-outline-secondary" onClick={this.onAccountSearch}>Search</button> */}
                                                                            <div className="accountlist">
                                                                            <div class={`loader_wrap ${ this.state.searchLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div>
                                                                            <ul>
                                                                                {transactionCreditAccountOptions}
                                                                            </ul>
                                                                            </div>
                                                                        </div>
                                                                    } 
                                                                </div>
                                                            </div>
                                                            }
                                                            {this.state.transaction_type !== "05" &&
                                                                <div className="form-group row">
                                                                    <label for="inputEmail3" className="col-sm-4" wfd-id="54">Amount</label>
                                                                        <div className="col-sm-8 d-flex">
                                                                            <input type="text" className="form-control w-50"  placeholder="" disabled value={this.state.accountCredit.credit_currency}/>
                                                                            <input type="text" name="credit_amount" onChange={this.onChangeCredit} value={this.state.accountCredit.credit_amount} className="form-control w100" placeholder="0.00" readOnly={this.state.creditAmountReadOnly}/>
                                                                            <p className="error">{this.state.validationCreditErrors.credit_account}</p>
                                                                        </div>
                                                                </div>
                                                            } 
                                                            <div className="form-group row">
                                                                <label className="col-sm-4">Value Date</label>
                                                                <div className="col-sm-8"> 
                                                                     <DatePicker readOnly={this.state.creditDateReadOnly}   minDate={new Date(moment())}  dateFormat="yyyy-MM-dd" className="form-control " value={ this.state.accountCredit.credit_value_date } selected={this.state.accountCredit.credit_value_date && new Date(this.state.accountCredit.credit_value_date)} name="debit_value_date"   onChange={(date) => {
                                                                    this.handleCreditDateChange('credit_value_date',date); 
                                                                }}  />
                                                                    <p className="error">{this.state.validationCreditErrors.credit_value_date}</p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label for="inputEmail3" className="col-sm-4">Details </label>
                                                                <div className="col-sm-8">
                                                                    <input type="text" className="form-control"  onChange={this.onChangeCredit} name="credit_details" value={this.state.accountCredit.credit_details} placeholder="" />
                                                                </div>
                                                            </div> 
                                                            <div className="form-group row">
                                                                <label for="inputEmail3" className="col-sm-4">Description </label>
                                                                <div className="col-sm-8">
                                                                    <input type="text"  className="form-control "  onChange={this.onChangeCredit} name="credit_description" value={this.state.accountCredit.credit_description} placeholder="" />
                                                                </div>
                                                            </div> 
                                                            { this.state.showRelatedClients &&
                                                                <>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <input type="text" name="search_account_parma" className="form-control" data-search="2" onChange={this.onChangeAccountSearch} placeholder="Client name, Client Number, Account Number"/> 
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <select class="form-control" name="search_account_type" data-search="2" onChange={this.onChangeAccountSearch}>
                                                                                    <option value="">Select Account Type</option>
                                                                                    {accountTypeOptions}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <label for="inputEmail3" className="col-sm-4 text-right">Clients</label>
                                                                            <div className="col-sm-8"> 
                                                                                {transactionRelatedClientCheckboxes.length == 0 &&
                                                                                    <p>No Account Found.</p>
                                                                                }
                                                                                {transactionRelatedClientCheckboxes.length > 0 &&
                                                                                    <div>
                                                                                        <input
                                                                                        type="checkbox"
                                                                                        onClick={this.handleAllChecked}
                                                                                        value="checkedall"
                                                                                        checked={this.state.checkedAll}
                                                                                        /> Check / Uncheck All
                                                                                    </div>
                                                                                }
                                                                                <div className="client_account_checkbox_wrap">
                                                                                    {transactionRelatedClientCheckboxes}
                                                                                </div> 
                                                                            </div>  
                                                                        </div>
                                                                    </>
                                                                }   
                                                        </div> 
                                                </div>
                                                }
                                            </div>
                                                <div className="row justify-content-end mr-0">  
                                                    <button type="submit" className="btn btn-primary bluebg"> <div className="buttonloader"><div class={`loader_wrap ${ this.state.saveloader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>Save</button> 
                                                </div>      
                                        </form>
                                 </div> 
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}
