import React, { Component } from "react";
import { Link } from 'react-router-dom';


export default class TopMenu extends Component {
    constructor(props) {
        super(props); 
            this.state = {
                relationAndOnlineaccess: localStorage.getItem("relationAndOnlineaccess"), 
                selectdClientName: localStorage.getItem("selectdClientName"),
                selectdClientNumber: localStorage.getItem("selectdClientNumber"),
            } 
    }; 
    
    async componentDidMount() {  
        this.setState({relationAndOnlineaccess:localStorage.getItem("relationAndOnlineaccess"),selectdClientName:localStorage.getItem("selectdClientName"),selectdClientNumber:localStorage.getItem("selectdClientNumber")}); 
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
         
      }
    render() { 
        return (        
                <ul className="nav leftmenuclient client-details-top-menu">
                    <li className="nav-item">                       
                                <Link
                                    className={`nav-link ${this.props.activeMenu == 'view_client' ? 'active' : ''}`} 
                                    to={{
                                    pathname: `/client/view-client/${this.props.clientId}`, 
                                    clientId:this.props.clientId,
                                    state: {
                                        clientId: this.props.clientId  
                                    }
                                    }}
                                    >
                                Client Details</Link>
                    </li> 
                    <li className="nav-item">
                            <Link
                            className={`nav-link ${this.props.activeMenu == 'client_address' ? 'active' : ''}`} 
                            to={{
                            pathname: `/client/client-address/${this.props.clientId}`, 
                            clientId:this.props.clientId,
                            state: {
                                clientId: this.props.clientId 
                                
                            }
                            }}
                        >
                            Address</Link> 
                    </li>
                    <li className="nav-item"> 
                        <Link
                        className={`nav-link ${this.props.activeMenu == 'client_identifications' ? 'active' : ''}`} 
                            to={{
                            pathname: `/client/client-identification/${this.props.clientId}`, 
                            clientId:this.props.clientId,
                            state: {
                                clientId: this.props.clientId 
                                
                            }
                            }}
                        >
                        Identification</Link>
                    </li> 
                        <li className="nav-item">
                            <Link
                            className={`nav-link ${this.props.activeMenu == 'client_online_access' ? 'active' : ''}`} 
                                to={{
                                pathname: `/client/client-online-access/${this.props.clientId}`, 
                                clientId:this.props.clientId,
                                state: {
                                    clientId: this.props.clientId 
                                }
                                }}
                            >Online Access</Link>
                        </li>  
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${this.props.activeMenu == 'client_relations' ? 'active' : ''}`} 
                                to={{
                                pathname: `/client/client-relation/${this.props.clientId}`, 
                                clientId:this.props.clientId,
                                state: {
                                    clientId: this.props.clientId, 
                                }
                                }}
                            >Client Relations</Link>
                        </li> 
                    <li className="nav-item d-none"> 
                        <Link
                            className={`nav-link ${this.props.activeMenu == 'client_documents' ? 'active' : ''}`} 
                            to={{
                            pathname: `/client/client-documents/${this.props.clientId}`, 
                            clientId:this.props.clientId,
                            state: {
                                clientId: this.props.clientId 
                                
                            }
                            }}
                        >Document</Link>
                    </li>
                </ul> 
        );
    }
}