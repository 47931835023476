import React from "react";
import PropTypes from "prop-types";
// import '../../assets/css/all.min.css';
// import '../../assets/css/icheck-bootstrap.min.css';
// import '../../assets/css/adminlte.min.css'; 
// import '../../assets/css/custom-css.css';

import '../../assets/css/client_detail.css';
import '../../assets/css/header_admin.css';
import '../../assets/css/header.css';
import '../../assets/css/login.css';
import '../../assets/css/sidebarmenu.css';
import '../../assets/css/style.css';







export default function DefaultLayout({ children }) {
  return(
    <>
      <div className="login-page" style={{minHeight: "512.8px"}}>{children}</div>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
};
