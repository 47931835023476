import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from "./RightMenu"
import TopMenu from "./TopMenu" 
export default class addNewRelation extends Component {


    state = {
        document_type: '',
        drivers_licence_number: '',
        issued_date: '',
        country_issued: '',
        location_issued: '',

        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token:'',
        

        validationErrors:{
            
            relatedClientNoError:'',
            relationError:'',
            percentError:'',
            narrativeError:''
      },
      showLicenseNumber:false,
      client_no:'',
      clientNumbers:[],
      relatedClientNo:'',
      relation:'',
      percent:'',
      narrative:''





    }
    async componentDidMount() {
        
        
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        //console.log(checkToken.name,'d')
        let accessToken = checkToken.access_token;
        this.setState({
            name: checkToken.name,
            role: checkToken.role,
            token:accessToken
        })

        //get listing
        await axios.get(`${window.$URL}client/get-client-numbers/${this.props.match.params.clientId}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
        .then(response => {
            
            if (response.data.status) {
                console.log(response.data.data.clientNumbers, 'responsesdssss')

                this.setState({client_no:response.data.data.clientDetails.client_no,
                    clientNumbers:response.data.data.clientNumbers
                
                })
                //let totalCount = response.data.data.length;
                

            }
        })
        .catch(error => {
            console.error(error.data)
        })
        }
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
            console.log('someerror',this.props.match.params.clientId)
        }




    }
    onChange = (e) => {
        e.preventDefault();
        console.log('ttttt',e.target.name,e.target.value)
        const { name, value } = e.target;
        this.setState({ [name]: value },this.validate)
        
    }
    validate = () => {
      this.setState({       
          relatedClientNoError:'',
          relationError:'',
          percentError:'',
          narrativeError:''
      });
      const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; 
       let relatedClientNoError= '';
       let relationError= '';
       let percentError='';
       let narrativeError=''; 
       let formvalidateStatus = true; 
      if (this.state.relatedClientNo.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{relatedClientNoError:'Field is required'}));
          formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{relatedClientNoError:''}));
      }  
      if (this.state.relation.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{relationError:'Field is required'}));
          formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{relationError:''}));
      }
      // if (this.state.percent.length < 1) {  
      //   this.setState(Object.assign(this.state.validationErrors,{percentError:'Please select country'}));
      //   formvalidateStatus = false;
      // }else{ 
      //     this.setState(Object.assign(this.state.validationErrors,{percentError:''}));
      // } 
      // if (this.state.narrative.length < 1) {  
      //   this.setState(Object.assign(this.state.validationErrors,{narrativeError:'Please enter expiry date'}));
      //   formvalidateStatus = false;
      // }else{ 
      //     this.setState(Object.assign(this.state.validationErrors,{narrativeError:''}));
      // }  
      return formvalidateStatus
    }
    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const {client_no,
        relatedClientNo,
        relation,
        percent,
        narrative,token } = this.state; 
        const isValid = this.validate();
       
        if (isValid) {
            //alert('pk');
            let teamData = {
                client_no: client_no,
                related_client_no: relatedClientNo,
                relation: relation,
                narrative: narrative,
                percent: percent,
                cm_id:this.props.match.params.clientId,
                
            }
            console.log(teamData)
           // return false;
            axios.post(`${window.$URL}client/insert-client-relation`, teamData,{ headers: {"Authorization" : `Bearer ${token}`}})
                .then(response => {
                    console.log(response, 'response')
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false,
                            drivers_licence_number:""
                        })
                        setTimeout(
                            () => this.props.history.push(`/client/client-relation/`+this.props.match.params.clientId), 
                            3000
                          );
                        //this.props.history.push(`/client/client-relation/`+this.props.match.params.clientId);
                        //this.componentDidMount();
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        })
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
        }
    } 
    render() {
        const { 
            document_typeError,
            drivers_licence_numberError,
            issued_dateError,
            country_issuedError,
            location_issuedError,
            
        } = this.state;
        return ( 
            <div class="wrapper">
                 <ToastContainer/> 
            <div class="container newContainer mainArea pt-0" id="formpage">
              <div class="row justify-content-center"> 
                <div class="lefeArea col-md-9 p-0">
                  <h3 class="heading">Create A New Relation Mervi Andersson</h3>
                  <TopMenu clientId={ this.props.match.params.clientId } activeMenu="client_relations"/> 
                  <div class="content-wrap">
                    <div class="row justify-content-center">  
                      <div class="col-md-6">
                        <h4 class="smallheading mb-0">Client Relation</h4>
                        <div class="tablebox">
                          <form>
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Client No</label>
                              <div class="col-sm-8">
                                <input type="text" class="form-control"  onChange={this.onChange} name="client_no" id="inputEmail3" placeholder="Client No" value={this.state.client_no} disabled/>
                              </div>
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Related Client No <sup>*</sup></label>
                              <div class="col-sm-8">
                                <select class="form-control" name="relatedClientNo" id="exampleFormControlSelect1" onChange={this.onChange}>
                                  <option value=''>--Related Client No--</option>
                                  {
                                      this.state.clientNumbers.length > 0 ?
                                      this.state.clientNumbers.map((data, index) => {
                                      return (        
                                          <option value={data}>{data}</option>
                                          );
                                      })
                                  :
                                  ''
                                  } 
                                </select>
                                <p className="error">{this.state.validationErrors.relatedClientNoError}</p>
                              </div> 
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Relation <sup>*</sup></label>
                              <div class="col-sm-8">
                                <select class="form-control"  name="relation"  id="exampleFormControlSelect1" onChange={this.onChange}>
                                  <option>Select Relation</option>
                                  <option>Signatory</option>
                                  <option>Online Banking</option>
                                  <option>Contact Person</option>
                                  <option>Father</option>
                                  <option>Mother</option>
                                  <option>Son</option>
                                  <option>Daughter</option>
                                  <option>Wife</option>
                                  <option>Husband</option>
                                  <option>Director</option>
                                  <option>Intermediary</option>
                                  <option>Nominee Shareholder</option>
                                  <option>Secretary</option>
                                  <option>UBBO</option> 
                                </select>
                                <p className="error">{this.state.validationErrors.relationError}</p>
                              </div>
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Narrative</label>
                              <div class="col-sm-8">
                                <input type="text" name="narrative" onChange={this.onChange} class="form-control" id="inputEmail3" placeholder="Narrative"/>
                                <p className="error">{this.state.validationErrors.narrativeError}</p>
                              </div> 
                            </div> 
                            <div class="form-group row">
                              <label for="inputEmail3" class="col-sm-4 col-form-label">Percent</label>
                              <div class="col-sm-8">
                                <input type="text" onChange={this.onChange} name="percent" class="form-control" id="inputEmail3" placeholder="Percent" />
                                <p className="error">{this.state.validationErrors.percentError}</p>
                              </div>
                            </div> 
                            <div class="form-group row text-center mt-2 ml-auto">
                              <div class="col-sm-12">
                                <div class="m-auto btncenter">
                                    <Link
                                        className="btn btn-primary bluebg"
                                        to={{
                                        pathname: `/client/client-relation/${this.props.match.params.clientId}`, 
                                        clientId:this.props.clientId,
                                        state: {
                                            clientId: this.props.clientId  
                                        }
                                        }}
                                    >
                                    Back
                                    </Link>
                                    <button type="button" onClick={this.onSubmit} class="btn btn-primary">Create</button>
                              </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>  
                 </div>
                 </div>
                </div> 
                  <RightMenu clientId={ this.props.match.params.clientId } activeMenu="client_relations"/>   
              </div>
            </div>
          </div> 
        );
    }
}
