import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageName: window.location.pathname.split("/"),
      name: "",
      role: "",
    };
  }
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //console.log(checkToken.name,'d')
    this.setState({
      name: checkToken.name,
      role: checkToken.role,
    });
  }

  // toggleSidebar() {
  //   let toggle;
  //   if (this.props.toggleSidebar) {
  //     toggle = false;assets/images/logo.png
  //   } else {
  //     toggle = true;
  //   }
  //   this.props.changeSidebar(toggle);
  // }

  render() {
    const { pageName, name, role } = this.state;
    return (
      <div class="header-wrap">
        <div class="container-fluid">
          <div class="row">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="fa fa-bars"></span>
            </button>
            <div class="col-md-2 col-5 offset-md-0 offset-1 logo">
              <a href="JavaScript:void(0);">
                {" "}
                <img
                  src={require("../../../assets/images/frux-logo.svg")}
                  alt="logo"
                  className="img-fluid"
                />{" "}
              </a>
            </div>
            <div class="col-md-9 col-6 ml-auto">
              <div class="rightmenu">
                <nav class="navbar navbar-expand-lg p-0">
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item"> 
                      <Link
                          className="nav-link"
                          to={{
                          pathname: `/client-portla/dashboard` 
                          }}
                      >  <i class="fa fa-home"></i> </Link>
                    </li>
                    <li class="nav-item d-none">
                      <a class="nav-link massege" href="#">
                        <i class="fa fa-envelope"></i>
                        <span class="badge">19</span>
                      </a>
                    </li>
                    <li class="nav-item dropdown d-none">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          class="fa fa-info-circle info-circle"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <div
                        class="dropdown-menu dropboxform "
                        id="signupform"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul class="adminmenu">
                          <li className="d-none">
                            <a href="getting-started.html">
                              <i class="fa fa-play"></i> Getting Started
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="fa fa-info"></i> Help
                            </a>
                          </li>
                          <li>
                            <a href="terms-and-conditions.html">
                              <i class="fa fa-file-text-o"></i> Terms And
                              Conditions
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-user-circle"></i>{" "}
                        <span>{this.state.name}</span>
                      </a>
                      <div
                        class="dropdown-menu dropboxform"
                        id="signupform"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul class="adminmenu"> 
                          <li>
                            <Link to="/logout">
                              <i class="fa fa-sign-in"></i> Log Out
                            </Link>{" "}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
