import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png" 
export default class PersonalAccounts extends Component { 
    state = { 
        mobileShow: false,
        buttonData: false,
        Details: '',
        documentId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',
        clientMasterId:'',
    }
    async componentDidMount() {

        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            let apiHeader = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + checkToken.access_token
                }
            };
            // alert(checkToken.access_token);
            //console.log(checkToken.name,'d')
            let accessToken = checkToken.access_token;

            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken,
                clientMasterId:checkToken.cm_id,
            }) 
            //get listing
            await axios.get(`${window.$URL}client/get-clients-accounts`,{
                    params: {
                        client_master_id :checkToken.cm_id,//client master id
                    },
                    headers: { "Authorization": `Bearer ${accessToken}` }
                })
                .then(response => {
                    console.log(response.data.data, 'response') 
                    if (response.data.status) {

                        let totalCount = response.data.data.length;
                        let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                        let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                        this.setState({
                            Details: response.data.data,
                            filterDetails: response.data.data.slice(0, this.state.pageLimit),
                            pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                            totalCount,
                            showingFrom,
                            showingTo,
                            paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Accounts'
                        })
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
            //end listing
        }

        if (this.props.match.params.hasOwnProperty('clientId')) {
           
        }
    }

    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    } 
    render() {
        const {
            showSweetAlert,
            modalDialog,
            filterDetails,
            activePage, totalCount, pageLimit, paginationCountText,

        } = this.state;
        return (
            <div className="wrapper wrappermax">
                <ToastContainer />  
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-9 p-0">
                            <h3 className="heading">Client's Accounts</h3>  
                            <div className="content-wrap">
                            <div class="row card card2 w-100 justify-content-left d-none">
                            <div class="card-body w-100">
                                    <div className="row">
                                        <label htmlFor="inputEmail3" className="col-sm-2"> Status Option</label>
                                        <div className="col-sm-10">
                                        <div className="custom-control custom-radio custom-control-inline mt-2"> 
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label" htmlFor="exampleCheck1"> All</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" className="form-check-input" id="exampleCheckActive" />
                                            <label className="form-check-label" htmlFor="exampleCheckActive"> Active</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" className="form-check-input" id="exampleCheckDormant" />
                                            <label className="form-check-label" htmlFor="exampleCheckDormant"> Dormant</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" className="form-check-input" id="exampleCheckPending" />
                                            <label className="form-check-label" htmlFor="exampleCheckPending"> Pending</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" className="form-check-input" id="exampleCheckFrozen" />
                                            <label className="form-check-label" htmlFor="exampleCheckFrozen"> Frozen</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" className="form-check-input" id="exampleCheckClosed" />
                                            <label className="form-check-label" htmlFor="exampleCheckClosed"> Closed</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" className="form-check-input" id="exampleCheckClosedRequest" />
                                            <label className="form-check-label" htmlFor="exampleCheckClosedRequest"> Closed Request</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" className="form-check-input" id="exampleCheckRejected" />
                                            <label className="form-check-label" htmlFor="exampleCheckRejected"> Rejected</label>
                                        </div>
                                        </div>
                                    </div> 
                            </div> 
                            <div class="card-body w-100">
                                <div className="row">
                                    <label htmlFor="inputEmail3" className="col-sm-2 ">Joint Accounts</label>
                                    <div className="col-sm-2">
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="checkbox" className="form-check-input" id="exampleCheckjoint" />
                                        <label className="form-check-label" htmlFor="exampleCheckjoint" />
                                    </div>
                                    </div>
                                </div> 
                            </div>
                            </div>
                                <div className="text-right p-0 d-none"> 
                                    <Link
                                    className="btn btn-primary bluebg"
                                    to={{
                                    pathname: `/client/add-clients-accounts/${this.state.clientMasterId}`, 
                                    clientId:this.props.clientId,
                                        state: {
                                            clientId: this.props.clientId 
                                            
                                        }
                                    }}
                                >  Open Account </Link>
                                </div>
                                <div className="tablebox p-0 w-100">
                                            <table className="table table-bordered table-responsive-sm">
                                                <thead>
                                                    <tr> 
                                                    <th scope="col">Account No</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Currency</th>
                                                    <th scope="col">Balance</th>
                                                    <th scope="col">Interest</th>
                                                    <th scope="col">Blocked</th>
                                                    <th scope="col">Available</th> 
                                                    <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                               
                                                <tbody>
                                                    {
                                                        filterDetails.length > 0 ?
                                                            filterDetails.map((data, index) => {

                                                                return (
                                                                    <tr> 
                                                                        <td>{data.account_no}</td>
                                                                        <td>{data.description}</td>
                                                                        <td>{data.currency}</td>
                                                                        <td>{data.balance}</td>
                                                                        <td>{data.interest}</td>
                                                                        <td>{data.blocked}</td> 
                                                                        <td>{data.available}</td> 
                                                                        <td class={data.status == '1' ? "bggreen" : "bgred"}>{data.status_text}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={5}>No result found</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="card-footer">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                                    </div>
                                                    <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                                        <Pagination
                                                            prevPageText='Prev'
                                                            nextPageText='Next'
                                                            firstPageText='First'
                                                            lastPageText='Last'
                                                            activePage={activePage}
                                                            itemsCountPerPage={pageLimit}
                                                            totalItemsCount={totalCount}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handlePageChange.bind(this)}
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                             </div>
                        </div>
                        {/* lefeArea end */} 
                    </div>
                </div>
            </div> 
        );
    }
}
