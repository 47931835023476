import React, { Component,useEffect, useState } from "react"; 
import { Link } from 'react-router-dom'; 
import MessageCount from '../../messageCount'; 
export default function Header() {
  const [small, setSmall] = useState(false);
  let checkTokenData = localStorage.getItem("userToken");
  let checkToken = JSON.parse(checkTokenData);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 63)
      );
    }
  }, []);
  
  
    return (
      <div className={`top_header ${
        small ? "fix-topheader" : ""
      }`}>
        <div className="container-fluid">
          <div className="row"> 
            <div className="col-md-2 col-5 offset-md-0 offset-1 logo">
              <a href={`${process.env.PUBLIC_URL}/dashboard`}> 
                <img src={require("../../../assets/images/frux-logo.svg")} alt="logo" className="img-fluid" /> 
              </a>
            </div>
            <div className="col-md-4 col-6 ml-auto">
              <div className="rightmenu">
                <nav className="navbar navbar-expand-lg p-0">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item"> 
                      <Link className="nav-link" to="/dashboard"> <i className="fa fa-home" /></Link> 
                    </li>
                    <MessageCount/>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"

                        aria-expanded="false"
                      >
                        <i className="fa fa-user-circle" />{" "}
                        <span>{checkToken.name}</span>
                      </a>
                      <div
                        className="dropdown-menu dropboxform"
                        id="signupform"
                        aria-labelledby="navbarDropdown"
                      >      
                           <ul className="adminmenu"> 
                              <li>
                                <Link to="/profile"><i class="fa fa-user"></i> My Profile</Link>  
                              </li> 
                              <li>
                                <Link to="/admin/accounts"><i class="fa fa-sign-in"></i> My Accounts</Link>  
                              </li> 
                              <li>
                                <Link to="/admin/accounts/transactions"><i class="fa fa-sign-in"></i>Transactions</Link>  
                              </li>
                              <li>
                                <Link to="/admin/bulk/transactions"><i class="fa fa-sign-in"></i>Bulk Transactions</Link>  
                              </li>
                             <li>
                                <Link to="/logout"><i class="fa fa-sign-in"></i> Log Out</Link>  
                              </li> 
                          </ul>  
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    ); 
}
