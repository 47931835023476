import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from "./RightMenu"
import TopMenu from "./TopMenu"
export default class viewClient extends Component {


    state = {
        document_type: '',
        complementary_information: '',
        confidential: '',

        documentTypeError:'',
        complementaryInformationError: '',
        confidentialError: '',



        

        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        
        
        buttonData: false,
        Details: '',
        
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',

        validationErrors:{
            documentTypeError: ''
        }



    }
    async componentDidMount() {


        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //console.log(checkToken.name,'d')
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            })
        }
        if (this.props.match.params.hasOwnProperty('clientId')) {
            console.log('someerror', this.props.match.params.clientId)
        }
    }
    onChange = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value },this.validate)
    }
    validate = () => {
        this.setState({

        documentTypeError:'',
        complementaryInformationError: '',
        confidentialError: '',
        });

        let documentTypeError = '';
        let complementaryInformationError = '';
        let confidentialError = '';
        let formvalidateStatus = true;
        if (this.state.document_type.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{documentTypeError:'Please select document type'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{documentTypeError:''}));
        }
        if (this.state.complementary_information.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{complementaryInformationError:'Please Enter Information'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{complementaryInformationError:''}));
        }

        if (this.state.confidential.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{confidentialError:'Please select confidential'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{confidentialError:''}));
        } 
       
        return formvalidateStatus
    }
    onSubmit = (e) => {
        //alert('gii');return false;
        e.preventDefault();
        
        const { document_type, complementary_information, confidential, token } = this.state;



        const isValid = this.validate();
        console.log(isValid,'isValid')
         if (isValid) {
            //alert('pk');
            let teamData = {
                document_type: document_type,
                complementary_information: complementary_information,
                confidential: confidential,
                cm_id: this.props.match.params.clientId, 
            }
            console.log(teamData);
            //return false;
            axios.post(`${window.$URL}client/insert-client-documents`, teamData, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    console.log(response, 'response')
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false
                        }) 
                        this.componentDidMount();
                        if(this.state.role === 'Super_Admin'){
                            this.props.history.push(`/client/client-documents/`+this.props.match.params.clientId);
                        }else{
                            this.props.history.push('/client-portla/personal-documents');
                        } 
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        })
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
        }
    }





    render() {
        const {
            documentTypeError,
            complementaryInformationError,
            confidentialError,

        } = this.state;
        return ( 
            <div class="wrapper">
              <ToastContainer/>           
              <div class="container newContainer mainArea pt-0" id="formpage">
                <div class="row justify-content-center"> 
                  <div class="lefeArea col-md-9 p-0"> 
                    { (this.state.role === 'Super_Admin' || this.state.role === 'Team_Member' )  &&
                        <TopMenu clientId={ this.props.match.params.clientId } activeMenu="client_documents"/>
                    } 
                    <div class="content-wrap">
                    <div class="row justify-content-center"> 
                      <div class="col-md-6">   
                        <h4 class="smallheading mb-0">Add Documents</h4>
                        <div class="tablebox">
                        <form onSubmit={this.onSubmit}> 
                            <div class="form-group row">
                              <label for="" class="col-sm-4 col-form-label">Document Type <sup>*</sup></label>
                              <div class="col-sm-8">
                                    <select className="form-control w-100" name="document_type" onChange={this.onChange} id="exampleFormControlSelect1">
                                        <option value="">Please Select </option>
                                        <option value="Driving Licence Number">Driving Licence Number</option>
                                        <option value="Family Registration Number">Family Registration Number</option>
                                        <option value="Identity Card">Identity Card</option>
                                        <option value="Passport">Passport</option>
                                    </select>
                                    <p className="error">{this.state.validationErrors.documentTypeError}</p>
                              </div>
                            </div> 
                            <div class="form-group row">
                              <label for="" class="col-sm-4 col-form-label">Complementary Info  <sup>*</sup></label>
                              <div class="col-sm-8">
                                <input type="text" name="complementary_information" className="form-control" id="inputEmail3" onChange={this.onChange} placeholder="Information" />
                                <p className="error">{this.state.validationErrors.complementaryInformationError}</p>
                              </div>
                            </div>
                            <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Confidential <sup>*</sup></label> 
                              <div class="col-sm-8">
                                    <select className="form-control w-100" name="confidential" onChange={this.onChange} id="exampleFormControlSelect1">
                                        <option value="">Please Select </option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option> 
                                    </select>
                                    <p className="error">{this.state.validationErrors.confidentialError}</p>
                              </div>
                            </div> 
                                <div className="form-group row text-center mt-2 ml-auto">
                                    <div className="col-sm-12">
                                        <div className="m-auto buttonwrp">
                                            { (this.state.role === 'Super_Admin' || this.state.role === 'Team_Member' )  &&
                                                    <Link
                                                        className="btn btn-primary bluebg"
                                                        to={{
                                                        pathname: `/client/client-documents/${this.props.match.params.clientId}`, 
                                                        clientId:this.props.clientId,
                                                            state: {
                                                                clientId: this.props.clientId 
                                                                
                                                            }
                                                        }}
                                                    > Back</Link>
                                            }
                                            { this.state.role === 'Client' &&
                                                    <Link
                                                        className="btn btn-primary bluebg"
                                                        to={{
                                                        pathname: `/client-portla/personal-documents`,  
                                                        }}
                                                    > Back</Link>
                                            }  
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>
                          </form>
                        </div> 
                      </div> 
                    </div>
                  </div>
                  </div> 
                  { (this.state.role === 'Super_Admin' || this.state.role === 'Team_Member' )  && 
                    <RightMenu clientId={ this.props.match.params.clientId } activeMenu="client_documents"/>
                  }   
                </div>
              </div>
          </div>
        );
    }
}
