import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png" 
export default class PersonalDocument extends Component {


    state = { 
        Details: '',
        documentId: '', 
        showSweetAlert: false,
        filterDetails: [], 
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',
        clientMasterId:'', 
    }
    async componentDidMount() {

        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            let apiHeader = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + checkToken.access_token
                }
            };
            // alert(checkToken.access_token);
            //console.log(checkToken.name,'d')
            let accessToken = checkToken.access_token;

            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken,
                clientMasterId:checkToken.cm_id
            })


            //get listing
            await axios.get(`${window.$URL}client/client-documents/${checkToken.cm_id}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
                .then(response => {
                    console.log(response.data.data, 'response') 
                    if (response.data.status) {

                        let totalCount = response.data.data.length;
                        let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                        let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                        this.setState({
                            Details: response.data.data,
                            filterDetails: response.data.data.slice(0, this.state.pageLimit),
                            pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                            totalCount,
                            showingFrom,
                            showingTo,
                            paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Documents'
                        })
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
            //end listing
        }

        if (this.props.match.params.hasOwnProperty('clientId')) {
            console.log('gdgdgdg', this.state.clientMasterId)
        }
    }

    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }

    deleteCheck = (delteId) => {
        this.setState({
            showSweetAlert: true,
            delteId: delteId,
        });
    };
    onCancel() {
        this.setState({
            showSweetAlert: false,
            delteId: ''
        })
    }
    confirmDelete(id) {
        this.setState({
            showSweetAlert: false
        }, () => {
            this.deleteFunc()
        })
    }
    deleteFunc = async () => {
        //alert(this.state.delteId);return false;
        const{token}=this.state;
        await axios.delete(`${window.$URL}client/remove-client-documents/` + this.state.delteId,{ headers: {"Authorization" : `Bearer ${token}`}})
            .then(response => {
                response.data.status ?
                    this.setState({
                        successMessage: response.data.message,
                        success: true,
                    }, () => {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.componentDidMount();
                    })
                    :
                    toast.error('Something Went Wrong', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            })
            .catch(error => {
                console.error(error.data)
            })
    }


    render() {
        const {
            showSweetAlert,
            modalDialog,
            filterDetails,
            activePage, totalCount, pageLimit, paginationCountText,

        } = this.state;
        return (
            <div className="wrapper wrappermax">
                  <ToastContainer /> 
                  {showSweetAlert ?
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        title="Confirm you wish to delete this item?"
                        onConfirm={() => this.confirmDelete()}
                        onCancel={() => this.onCancel()}
                        focusCancelBtn
                    />
                    :
                    null
                }  
                <div className="container mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-12">
                            <h3 className="heading">Client's Documents</h3> 
                            <div className="content-wrap"> 
                                <div className="text-right p-0 mt-2 mb-3">
                                    <Link
                                        className="btn btn-primary bluebg"
                                        to={{
                                        pathname: `/client/add-client-documents/${this.state.clientMasterId}`, 
                                        clientId:this.props.clientId,
                                        state: {
                                            clientId: this.props.clientId 
                                            
                                        }
                                        }}
                                    >
                                        Add New Documents</Link>
                                </div>
                                <div className="tablebox p-0 w-100">
                                            <table className="table table-bordered table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        {/* <th scope="col">View</th> */}
                                                        <th scope="col">Id</th>
                                                        <th scope="col">Document Type</th>
                                                        <th scope="col">Complementary Information</th>
                                                        <th scope="col">Confidential</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th> 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filterDetails.length > 0 ?
                                                            filterDetails.map((data, index) => {

                                                                return (
                                                                    <tr>
                                                                        {/* <td className="text-center">
                                                                            <button><a href="#"><i className="fa fa-search" /></a></button>
                                                                        </td> */}
                                                                        <td>{data.id}</td>
                                                                        <td>{data.document_type}</td>
                                                                        <td>{data.complementary_information}</td>
                                                                        <td>{data.confidential === 1 ? 'Yes' :"No"}</td>
                                                                        <td>{data.status_text}</td>
                                                                        <td className="text-center"> 
                                                                        <Link  to={{
                                                                        pathname: `/client/edit-client-document/${this.state.clientMasterId}/${data.id}`,  
                                                                        state: {
                                                                            clientId: data.client_mid 
                                                                            
                                                                        }
                                                                        }}><button><i className="fa fa-edit" /></button></Link>
                                                        <button onClick={() => this.deleteCheck(data.id)}><i className="fa fa-trash" /></button>
                                                                </td>

                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={5}>No result found</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="card-footer">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                                    </div>
                                                    <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                                        <Pagination
                                                            prevPageText='Prev'
                                                            nextPageText='Next'
                                                            firstPageText='First'
                                                            lastPageText='Last'
                                                            activePage={activePage}
                                                            itemsCountPerPage={pageLimit}
                                                            totalItemsCount={totalCount}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handlePageChange.bind(this)}
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  
                            </div>
                        </div>
                        {/* lefeArea end */} 
                    </div>
                </div>
            </div> 
        );
    }
}
