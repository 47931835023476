import React, { Component } from "react"; 
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print'
import axios from "axios";  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import sign from "../../assets/images/sign.png"; 
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class Statements extends Component {
  state = {
    name: "",
    role: "",
    account_type:'',
    account_no:'',
    start_to:'',
    end_to:'',
    filter_by:'7',
    accountTypes:[],
    transactionAccounts:[],
    transactionRecords:[],
    clientName:'',
    accountName:'',
    accountNumber:'',
    btnLoader:false,
    pdfUrl:'',
    message:'',
    validationErrors:{
      accountTypeError:'',
      startToError:'',
      endToError:'',
      filterByError:''

    }
  };
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //console.log(checkToken.name,'d')
    this.setState({
      name: checkToken.name,
      role: checkToken.role, 
    });
    this.getConfig();
  }
  async getConfig()
  { 
      let clientId=this.props.match.params.clientId
    
      let checkTokenData = localStorage.getItem("userToken");
      if(checkTokenData){
          let checkToken = JSON.parse(checkTokenData);

          let accessToken = checkToken.access_token; 
          //get listing
          await axios.get(`${window.$URL}getConfigs`,{
            params: {
                transaction_account :'01', 
                account_type        : true,
                account_types       : true, 
            },
            headers: { "Authorization": `Bearer ${accessToken}` }
          })
          .then(response => {
              console.log(response.data.data.currency, 'response by edit')
              if (response.data.status) { 
                  if(response.data.data.account_types){
                      this.setState({accountTypes:response.data.data.account_types}); 
                  } 
                  if(response.data.data.transaction_accounts){
                    this.setState({transactionAccounts:response.data.data.transaction_accounts}); 
                  } 
               
              }
          }) 
          .catch(error => {
              console.error(error.data)
          })
            //end listing

      } 
    }
  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value, 
    });
    this.setState({pdfUrl:''});
    if(e.target.name === 'filter_by' && e.target.value !== '1'){
      this.setState({start_to:'', end_to:''});
    }
    
  }
  async OnSearch()
  {
      console.log('test',this.state)
     
      let checkTokenData = localStorage.getItem("userToken");
      if(checkTokenData){
      let checkToken = JSON.parse(checkTokenData);
      
      let accessToken = checkToken.access_token;
      var params ={
          account_type:this.state.account_type,
          account_no:this.state.account_no,
          start_to:this.state.start_to,
          end_to:this.state.end_to, 
      }
      const isValid = this.validate();
      if(isValid){
        this.setState({pagelaoder:true});
        this.setState({btnLoader:true,pdfUrl:''});
        console.log(params); 
        //axios.post(`${window.$URL}client/view-statement`, params, { headers: { "Authorization": `Bearer ${accessToken}` } })
        let start_to = '';
        let end_to = '';
        if(this.state.start_to){
            start_to = moment(moment(this.state.start_to)).format('Y-MM-DD');
        }
        if(this.state.end_to){
            end_to = moment(moment(this.state.end_to)).format('Y-MM-DD');
        }
         await axios.get(`${window.$URL}transaction/histrory`,{
              params: {
                  account_type:this.state.account_type,
                  search_type:this.state.filter_by,
                  account_no:this.state.account_no,
                  start_to:start_to,
                  end_to:end_to,
                  cm_id:checkToken.cm_id,
                  pdfdownload:true
              },
                  headers: { "Authorization": `Bearer ${accessToken}` }
          })
          .then(response => {
              console.log(response.data.data, 'response')
              if (response.data.status) {
                  this.setState({
                    transactionRecords:response.data.data.transactions.length > 0 ? response.data.data.transactions : [],
                    btnLoader:false,
                    clientName:response.data.data.clientName,
                    accountName:response.data.data.accountName,
                    accountNumber:response.data.data.accountNumber,
                  });
                  this.setState({pdfUrl:response.data.data.pdf_url});
                }else{
                this.setState({btnLoader:false,pdfUrl:'',message:'No Record Found For Selected Filter Data'});
                toast.error("No result found", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
              }
          })
          .catch(error => {
              console.error(error.data);
              this.setState({btnLoader:false,pdfUrl:''});
          })
        }
    //end listing

      }
  }
  validate = () => { 
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;  
    let emailError = '';  
    const { validationErrors } = this.state;
    let formvalidateStatus = true; 

        // if (this.state.account_type.length < 1) {  
        //     this.setState(Object.assign(this.state.validationErrors,{accountTypeError:'Please Select Account Type'}));
        //     formvalidateStatus = false;
        // }else{
        //     this.setState(Object.assign(this.state.validationErrors,{accountTypeError:''}));
        // } 
        if (this.state.account_no.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{accountNoError:'Please Select Account'}));
          formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{accountNoError:''}));
        } 
        if (this.state.filter_by.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{filterByError:'Please Select Statment Type'}));
          formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{filterByError:''}));
        }
        if(this.state.filter_by === '1'){
          if (this.state.start_to.length < 1) { 
              this.setState(Object.assign(this.state.validationErrors,{startToError:'Please Select Start Date'}));
              formvalidateStatus = false;
          }
          else{
              this.setState(Object.assign(this.state.validationErrors,{startToError:''}));
          }
          if (this.state.end_to.length < 1) { 
              this.setState(Object.assign(this.state.validationErrors,{endToError:'Please Select End From Date'}));
              formvalidateStatus = false;
          }
          else{
              this.setState(Object.assign(this.state.validationErrors,{endToError:''}));
          }
        }
     
    return formvalidateStatus 
  }
  async getClientAccounts(accountType)
    { 
            let clientId=this.props.match.params.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
                this.setState({searchLoader:true});
                let accessToken = checkToken.access_token; 
                //get listing
                 await axios.get(`${window.$URL}getConfigs`,{
                    params: { 
                        search_account_parma :this.state.accountSearch.search_account_parma, 
                    },
                    headers: { "Authorization": `Bearer ${accessToken}` }
                })
                .then(response => {
                   
                    if (response.data.status) { 
                        if(response.data.data.transaction_accounts){ 
                            this.setState({transactionAccounts:response.data.data.transaction_accounts}); 
                            this.setState({searchLoader:false}); 
                        } 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

            }  
    }
    handleDateChange = (name,date) => {  
      if(date){
        this.setState({
          [name]: moment(moment(date)), 
        })
      }else{
          this.setState({
              [name]: "", 
            })
      }
    }
  render() {
    const { name, role,accountTypes,transactionAccounts,transactionRecords } = this.state;  
    let accountTypeOptions = accountTypes.map((item, index) =>
        <option value={item.value}>{item.name}</option>
    );
    let accountNumberOptions = transactionAccounts.map((item, index) =>
        <option value={item.account_id}>{item.account}</option>
    );
    return (
      <>
      <div className="wrapper wrappermax">
      <ToastContainer /> 
    <div className="mainheading2 text-center w100 m-0">
      Statement of Account
    </div>
    <div className="container mainArea " id="formpage">
        <div className="row justify-content-center">
          <div className="lefeArea col-md-5">
            <div className="tablebox ">
              <form>
                  {/* <div className="form-group row">
                    <label for="inputEmail3" className="col-sm-4 col-form-label">Account <sup>*</sup></label>
                    <div className="col-sm-8">
                      <select className="form-control" id="exampleFormControlSelect1" value={this.state.account_type} name="account_type" onChange={this.handleInputChange}>
                        <option value="">Please Select an Account</option>
                        {accountTypeOptions}
                      </select>
                      <p className="error">{this.state.validationErrors.accountTypeError}</p>
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <label for="inputEmail3" className="col-sm-4 col-form-label">Account Number<sup>*</sup></label>
                    <div className="col-sm-8">
                      <select className="form-control" id="exampleFormControlSelect1" value={this.state.account_no} name="account_no" onChange={this.handleInputChange}>
                        <option value="">Please Select an Account</option>
                        {accountNumberOptions}
                      </select>
                      <p className="error">{this.state.validationErrors.accountNoError}</p>
                    </div>
                  </div>
                  <div className="form-group row mb-5">
                      <label for="inputEmail3" className="col-sm-4">Statment Type</label>
                            <div className="col-sm-8">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="filter_by" id="filter_by_7days" value="7" onChange={this.handleInputChange} checked={this.state.filter_by == '7'}/>
                                <label class="form-check-label" for="filter_by_7days">
                                    Last 7 Days
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="filter_by" id="filter_by_30days" value="30" onChange={this.handleInputChange} checked={this.state.filter_by == '30'}/>
                                <label class="form-check-label" for="filter_by_30days">
                                  Last month 30 days
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="filter_by" id="filter_by_3months" value="3" onChange={this.handleInputChange} checked={this.state.filter_by == '3'}/>
                                <label class="form-check-label" for="filter_by_3months">
                                  Last 3 months
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="filter_by" id="filter_by_custom" value="1" onChange={this.handleInputChange} checked={this.state.filter_by == '1'}/>
                                <label class="form-check-label" for="filter_by_custom">
                                  Custom
                                </label>
                              </div>
                              <p className="error">{this.state.validationErrors.filterByError}</p>
                          </div> 
                  </div>
                  
                  {this.state.filter_by === '1' &&
                  <>
                  <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4">From</label> 
                      <div className="col-sm-8 customdatesm">
                          <DatePicker dateFormat="yyyy-MM-dd" className="form-control " value={ this.state.start_to } selected={this.state.start_to && new Date(this.state.start_to)} name="start_to" onChange={(date) => {
                              this.handleDateChange('start_to',date); 
                          }}  /> 
                          <p className="error">{this.state.validationErrors.startToError}</p>
                      </div>
                  </div>
                  <div className="form-group row">
                      <label for="inputEmail3" className="col-sm-4">Period To</label> 
                      <div className="col-sm-8 customdatesm"> 
                          <DatePicker dateFormat="yyyy-MM-dd" className="form-control " value={ this.state.end_to } selected={this.state.end_to && new Date(this.state.end_to)} name="end_to" onChange={(date) => {
                              this.handleDateChange('end_to',date); 
                          }}  /> 
                            <p className="error">{this.state.validationErrors.endToError}</p>
                      </div>
                  </div> 
                  </>
                }
                  {/* <div className="form-group row">
                    <label for="inputEmail3" className="col-sm-7">Add Beneficiary / Originator Personal Details</label>
                    <div className="col-sm-2 text-left mt-2">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="checkbox" className="form-check-input" id="exampleCheckjoint"/>
                        <label className="form-check-label" for="exampleCheckjoint"></label>
                      </div> 
                    </div>
                  </div>  */}
                  <div className="row justify-content-center mr-0"> 
                      <button type="button" onClick={() => this.OnSearch()} className="btn btn-primary mleft7">
                          <div className="buttonloader"><div class={`loader_wrap ${ this.state.btnLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                          View Report
                      </button> 
                      {this.state.pdfUrl &&
                        <a href="javascript:void(0)" onClick={()=> window.open(this.state.pdfUrl, "_blank")} className="btn btn-success" traget="_BLANK">Download</a>
                      }                 
                  </div>
              </form> 
            </div> 
          </div> 
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div>
                {transactionRecords.length > 0 &&
                  <ReactToPrint
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten. 
                        return <a href="#" className="print-btn">Print</a> 
                    }}
                    content={() => this.componentRef}
                  /> 
                  }
                  <div ref={el => (this.componentRef = el)}>
                    <div className="tablebox p-0 w-100">
                      {transactionRecords.length > 0 &&
                        <table className="table table-bordered table-responsive-sm">
                          <tbody>
                            <tr>
                              <td width="50%"> 
                                {this.state.filter_by == 1 ?
                                  <p>Date & Time: {moment(moment(this.state.start_to)).format('Y-MM-DD')} To {moment(moment(this.state.end_to)).format('Y-MM-DD')}</p> 
                                :
                                <p>Date & Time: N/A</p> 
                                }
                              </td>
                              <td  width="50%">
                                <p>Report Type: Account Statement</p>
                              </td> 
                            </tr>
                            <tr>
                              <td>
                                <p>Client Name: {this.state.clientName}</p> 
                              </td>
                              <td>
                                <p>Account Name: {this.state.accountName}</p> 
                              </td> 
                            </tr>
                            <tr> 
                              <td>
                                <p>Account Number: {this.state.accountNumber}</p> 
                              </td> 
                            </tr>
                          </tbody>
                        </table>
                      } 
                       <table className="table table-bordered table-responsive-sm">
                          <thead>
                              <tr> 
                              <th scope="col">Transaction Id</th>
                              <th scope="col">Type</th>
                              <th scope="col">Date</th> 
                              {/* <th scope="col">Amount</th> */}
                              <th scope="col">Account Details</th>
                              {/* <th scope="col">From Currency</th>   */}
                              <th scope="col" className="bgdarkgreen">Credit</th> 
                              <th scope="col" className="bgred">Debit</th>
                              <th scope="col">Balance</th>
                              </tr>
                          </thead> 
                          <tbody>
                              {
                                  transactionRecords.length > 0 ?
                                  transactionRecords.map((data, index) => {

                                          return (
                                              <tr> 
                                                  <td>{data.transaction_no}</td>
                                                  <td>{data.transaction_type}</td>
                                                  <td>{data.transaction_date}</td> 
                                                  {/* <td>{data.transaction_amount}</td> */}
                                                  <td>{data.from_account}</td>
                                                  {/* <td>{data.transaction_from_currency}</td> */}
                                                  {/* <td>{data.transaction_to_account_no}</td> */}
                                                  {/* <td>{data.transaction_to_currency}</td> */}
                                                  {/* <td>{data.transaction_details}</td> */}
                                                  <td className="textgreen">{data.credit_amount}</td>
                                                  <td className="textred">{data.debit_amount}</td>
                                                  <td>{data.transaction_balance}</td>
                                              </tr>
                                          )
                                      })
                                      :
                                      <tr>
                                          <td colSpan={5}>No result found</td>
                                      </tr>
                              }
                          </tbody>
                      </table>
                                   
                    </div>        
                  </div>
              </div>
          </div>
        </div>
     </div>
    </div>
      </>
    );
  }
}
